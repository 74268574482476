import { collection, doc, getDoc, getDocs, orderBy, query, Timestamp, where } from "firebase/firestore";
import { DB, Functions } from "../firebaseConfig/FirebaseConfig";
import { IShipment } from "../interfaces/Shipment";
import { containerSharedDBInterface, containerSharedInterface } from "../interfaces/containerSharedInterface";
import { GetServiceDetail } from "./Service.service";
import { QuotationContainerSharedInterface } from "../interfaces/QuotationContainerSharedInterface";
import { httpsCallable } from "firebase/functions";
import { TypeTransport } from "../@types/TypeTransport";

export const searchContainerShared = async (typeTransport: string, origin: IShipment, destination: IShipment,
    totalWeight: number, totalVolume: number, dateFrom: Date) : Promise<containerSharedInterface[]> => {
    
    const dateFromTimestamp = Timestamp.fromDate(dateFrom);
    const q = query(collection(DB, "containerShared"), where("status", "==", true), where("isFullContainer", "==", false),
    where("typeTransport", "==", typeTransport), where("origin", "==", origin), where("destination", "==", destination),
    where("receiveDate", ">=", dateFromTimestamp));
    const response = await getDocs(q);
    let data: containerSharedInterface[] = [];
    
    if (!response.empty) {

        const containers = response.docs.map(doc => ({id: doc.id, ...doc.data()} as containerSharedDBInterface));
        
        const filteredContainers = containers.filter(container => container.usedCapacity + totalVolume <= container.maxLoadCapacity);
        
        const containerDetailsPromises = filteredContainers.map(async (container) => {
            const service = await GetServiceDetail(container.serviceId);
            return {
                ...container,
                companyLogo: service.companyLogo || '',
                companyName: service.companyName,
                country: service.country,
                numComments: service.numComments,
                rating: service.rating
            };
        });
        
        data = await Promise.all(containerDetailsPromises);
    }
    
    return data;
};

export const getAllContainerSharedFromDate = async (typeTranport: TypeTransport, dateFrom: Date) : Promise<containerSharedInterface[]> => {
    const dateFromTimestamp = Timestamp.fromDate(dateFrom);
    const q = query(collection(DB, "containerShared"), where("status", "==", true), where("isFullContainer", "==", false),
    where("typeTransport", "==", typeTranport), where("receiveDate", ">=", dateFromTimestamp), orderBy("receiveDate", "asc"));
    const response = await getDocs(q);
    let data: containerSharedInterface[] = [];
    
    if (!response.empty) {
        const containers = response.docs.map(doc => ({id: doc.id, ...doc.data()} as containerSharedDBInterface));
        
        const containerDetailsPromises = containers.map(async (container) => {
            const service = await GetServiceDetail(container.serviceId);
            return {
                ...container,
                companyLogo: service.companyLogo || '',
                companyName: service.companyName,
                country: service.country,
                numComments: service.numComments,
                rating: service.rating
            };
        });
        
        data = await Promise.all(containerDetailsPromises);
    }
    
    return data;
}

export const createOrderContainerShared = async (quotationContainerShared: QuotationContainerSharedInterface, token: string) => {
    const createContainerSharedOrder = httpsCallable(Functions, 'createContainerSharedOrder');
    return createContainerSharedOrder({quotationContainerShared, token})
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.log(error);
        return error;
    });
};

// method to get the container shared by id
export const GetContainerSharedById = async (id: string) : Promise<containerSharedDBInterface> => {
    const response = await getDoc(doc(DB, 'containerShared', id));
    return new Promise((resolve, reject) => {
        if (response.data()) {
            const data: containerSharedDBInterface = response.data() as containerSharedDBInterface;
            resolve(data);
        } else {
            reject(new ErrorEvent('not-found', {message: 'not-found'}));
        }
    });
};
