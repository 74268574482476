import React, { useRef } from 'react';


import "./CardContainer.css";
import { Rating } from '../Rating/Rating';
import { CommentCount } from '../CommentCount/CommentCount';
import { CityCountryDisplay } from '../CityCountryDisplay/CityCountryDisplay';
import { VerifyBlack } from '../../icons/solid/VerifyBlack';
import { VerifyBlue } from '../../icons/solid/Verify_blue';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { ProgressAnimation } from '../ProgressAnimation/ProgressAnimation';
import { containerSharedInterface } from '../../interfaces/containerSharedInterface';
import { FormatDateMinString } from '../../utils/formatDate';
import { BackArrowRight } from '../../icons/solid/Back_Arrow_Right';

interface CardContainerProps {
    container: containerSharedInterface
    onClickButton: (container: containerSharedInterface) => void;
    price: number;
};

/*
    Este componente muestra información clave de una importación 
    compartida, incluyendo la fecha de recogida, el lugar de origen y 
    los datos de otros usuarios que participan en la importación. 
    Permite a los usuarios visualizar y gestionar detalles de sus envíos de 
    manera clara y sencilla.
*/

export const CardContainer: React.FC<CardContainerProps> = ({
    container,
    onClickButton,
    price
}) => {

    // const { verify, numberVerify } = service;

    const verify = true;
    const numberVerify = 2;

    return (
    <div className='box-shadow-card cardContainer' onClick={() => container.usedCapacity !== container.ratedCapacity ? onClickButton(container) : null}>
        <div className='cardContainer-content-img-service'>
            <img 
                className='cardContainer-service-img'
                src={container.containerImage}/>
        </div>
        <div className='cardContainer-detail'>
            <div className='cardContainer-row-2 cardContainer-row-2_'>
                <div className='cardContainer-badget'>
                    <div className='tinytext-header cardContainer-badget-content'>Más caro</div>
                </div>
            </div>
            <div className='cardContainer-fact-service'>
                <div className="cardContainer-fact-service-header">
                    <div className='cardContainer-fact-service-header-img'>
                        <img 
                            className='cardContainer-service-img'
                            src={container.containerImage}/>
                    </div>
                    <div className='paragraph-header cardContainer-fact-service-title'>
                        {container.containerName}
                    </div>
                    {/* <div className="cardContainer-fact-service-check">
                        {verify && numberVerify <= 2 ? 
                        <VerifyBlack onClick={()=>{}}/> : 
                        <VerifyBlue onClick={()=>{}}/>}
                    </div> */}
                </div>
                <div className={"cardContainer-fact-service-detail"} >
                    <Rating rating={container.rating} /> 
                    <CommentCount commentsCount={container.numComments} />
                    <CityCountryDisplay
                        city={""}
                        country={container.country.alpha2Code}
                        flagImg={container.country.imgFlag}
                    />
                </div>
            </div>

            <div className='cardContainer-row-1'>
                {/* <div className='cardContainer-badget'>
                    <div className='tinytext-header cardContainer-badget-content'>Más caro</div>
                </div> */}
                <div className='cardContainer-programation cardContainer-programation-content_'>
                    <div className='cardContainer-programation-content '>
                        <div className='smalltext-header cardContainer-text-gray'>Salida desde</div>
                        <div className='smalltext-header'>{container.origin.city}</div>
                    </div>
                </div>

                <div className='cardContainer-programation'>
                    <div className='cardContainer-programation-content'>
                        <div className='smalltext-header cardContainer-text-gray'>Recibe hasta</div>
                        <div className='smalltext-header'>{FormatDateMinString(container.receiveDate.toDate())}</div>
                    </div>
                </div>

                <div className='cardContainer-programation'>
                    <div className='cardContainer-programation-content'>
                        <div className='smalltext-header cardContainer-text-gray'>Llega a Perú</div>
                        <div className='smalltext-header'>{FormatDateMinString(container.arrivalDate.toDate())}</div>
                    </div>
                </div>

                <div className='cardContainer-programation'>
                    <div className='cardContainer-programation-content'>
                        <div className='smalltext-header cardContainer-text-gray'>Entrega en</div>
                        <div className='smalltext-header'>{container.deliveryCity}</div>
                    </div>
                </div>
            </div>

            {/* <div className='cardContainer-progress'>
                <ProgressAnimation progressPercentage={container.usedCapacity/container.ratedCapacity}/>
            </div> */}

            
            <div className='cardContainer-row-2'>
                <div className='cardContainer-origin-destination'>
                    <div className='tinytext-header'>{container.origin.city}</div>
                    <BackArrowRight/>
                    <div className='tinytext-header'>{container.destination.city}</div>
                </div>
                <div className={`${container.usedCapacity !== container.ratedCapacity ? "cardContainer-button--active" : "cardContainer-button--disabled"} cardContainer-button`}>                   
                    <div className='smalltext cardContainer-text-white'>{container.usedCapacity !== container.ratedCapacity ? "Desde" : "No disponible"}</div>
                    <div className='small-header-medium cardContainer-text-white'>$ {price<1000 ? price.toFixed(2) : ((price/1000).toFixed(price%1000 === 0 ? 0 : 2) + "K")}</div>
                </div>
            </div>
        </div>
    </div>
  );
};
