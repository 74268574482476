import React, { useEffect, useState } from "react";

// Importando componentes
import { Badge } from "../Badge/Badge";

// Importando iconos
import { ArrowUp } from "../../icons/outline/ArrowUp";
import { ArrowDown } from "../../icons/solid/ArrowDown";
import { ArkabiaOp } from "../../icons/outline/ArkabiaOp";

// Importando estilos
import './DashboardElementsSupplier.css';
import { ContentQuote } from "../ContentQuote/ContentQuote";
import { RequestQuoteInterface } from "../../interfaces/RequestQuote";
import { OrderInterface } from "../../interfaces/OrderInterface";
import { TableResponsive } from "../TableResponsive/TableResponsive";
import { ColumnTableSupplierResponsiveInterface } from "../../interfaces/ColumnTableResponsiveInterface";
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

interface DashboardElementsSupplierProps {
    id: string; // id de la solicitud de cotizacion
    date: string;
    open?: boolean; // contrala si esta abierto el collapse
    dataImportation: OrderInterface | RequestQuoteInterface;
    type: "requestQuote" | "order"; // tipo de la solicitud de cotizacion
    badge?: boolean; // si tiene badge
    handleClickQuote: (operationId: string | undefined, requestQuoteId: string | undefined, quotationId: string | undefined) => void;
    handleClickOrder: (operationId: string | undefined, orderId: string | undefined, orderStep: number) => void;
}

export const DashboardElementsSupplier: React.FC<DashboardElementsSupplierProps> = ({
    id,
    date,
    open = false,
    dataImportation,
    handleClickQuote,
    handleClickOrder,
    type,
    badge
}) => {

    const [expanded, setExpanded] = useState(open);

    const [columnTitle, setColumnTitle] = useState<string[]>([]);
    const [columnData, setColumnData] = useState<ColumnTableSupplierResponsiveInterface[]>([]);

    const setColumnsTitle = () => {
        const isWideScreen = window.innerWidth > 691;
        const commonColumns = type === "requestQuote" ? ['Cliente', 'Origen', 'Valor'] : ['Cliente', 'Órden', 'Valor'];
        const commonColumnsWithMessage = [...commonColumns, 'Último mensaje'];

        if (type === "requestQuote") {
            if (isWideScreen) {
                setColumnTitle(badge ? ['Cliente', 'Origen', 'Valor'] : commonColumnsWithMessage);
            } else {
                setColumnTitle(commonColumns);
            }
        } else {
            if (isWideScreen) {
                setColumnTitle(badge ? ['Cliente', 'Órden', 'Valor'] : commonColumnsWithMessage);
            } else {
                setColumnTitle(commonColumns);
            }
        }
    }

    const setColumnsData = () => {
        // Verificar que dataImportation, type, ConverMoneyFormat y setColumnData estén definidos
        if (!dataImportation || !type || !ConverMoneyFormat || !setColumnData) {
            console.error("Variables necesarias no están definidas");
            return;
        }
    
        let data: any = {
            id: dataImportation.id || "",
            Cliente: type === "requestQuote"
                ? (dataImportation?.user && "name" in dataImportation.user ? dataImportation.user.name : "" ) 
                : (dataImportation?.user && "userName" in dataImportation.user ? dataImportation.user.userName : ""),
            Valor: "$ "+ConverMoneyFormat(type === "requestQuote" 
                ? ("customs" in dataImportation ? (dataImportation.customs.value?.toString() || "0") : "0" ) 
                : (dataImportation.price?.toString() || "0")),
            ['Último mensaje']: window.innerWidth > 691 ? (dataImportation.lastMessage || "Todavía no hay ningún mensaje") : "",
        }
    
        if (type === "requestQuote") {
            data["Origen"] = (typeof(dataImportation.transport) === "object" && "origin" in dataImportation.transport ? dataImportation.transport.origin?.city : "") ;
        } else {
            data["Órden"] = dataImportation.number?.toString() || "";
        }
    
        setColumnData([data]);
    }

    const handleQuoteItemClick = (requestQuoteId: string | undefined, quotationId: string | undefined) => {
        handleClickQuote(dataImportation?.operationId || "", requestQuoteId, quotationId);
    }

    const handleOrderItemClick = (orderId: string, orderStep: string) => {
        handleClickOrder(dataImportation.operationId || "", orderId, Number(orderStep));
    }

    const handleItemClick = () => {
        if(type == "requestQuote"){
            handleQuoteItemClick(dataImportation.id, dataImportation.quotationId);
        }else{
            handleOrderItemClick(dataImportation.id || "", "1");
        }
    } 

    useEffect(()=> {
        setExpanded(open);
    }, [open]);

    useEffect(() => {
        setColumnsTitle();
        setColumnsData();
    }, [dataImportation]);

    return (
        <>
            <div className="dashboardElements-supplier box-shadow-card">
                <div  className={`dashboardElements-supplier-header ${!expanded && "dashboardElements-supplier-header__border"}`}>
                    <div className="dashboardElements-supplier-content-left" onClick={()=> setExpanded(!expanded)}>
                        <ArkabiaOp/>
                        <div className="dashboardElements-supplier-title">
                            <div className="smalltext-header dashboardElements-supplier__title">{dataImportation?.title}</div>
                            {/* <div className="smalltext dashboardElements-supplier__detail">{type}</div> */}
                        </div>
                        <div className="dashboardElements-supplier-explain">
                            {expanded?<ArrowUp /> :<ArrowDown />}
                        </div>
                    </div>
                    
                    <div className="dashboardElements-supplier-content-right">
                        {type === "requestQuote" ? 
                        <ContentQuote 
                            requestId={dataImportation.id || ""}
                            statusLabel={"statusProvider" in dataImportation ? (dataImportation.statusProvider || "") : ''} 
                            number={Number(dataImportation.number) || 0}
                            handleClickStatus={()=>{}}
                            onClick={()=> setExpanded(expanded ? expanded : !expanded)}
                        /> :
                        <>
                            {/* { badge ? null :<CargoStatus phase={cargoStatus} date={date} onClick={()=> setExpanded(!expanded)}/> } */}
                        </>}
                        
                            <div className="tinytext-header dashboardElements-date-text" onClick={()=> setExpanded(!expanded)}>{date}</div>
                            { badge ? <Badge number={dataImportation.number?.toString() || "0"} /> : null}
                        {expanded?<ArrowUp onClick={()=> setExpanded(!expanded)}/> :<ArrowDown onClick={()=> setExpanded(!expanded)}/>}
                    </div> 
                </div>

                {/** Contenido al expandir */}
                { expanded && <div className="dashboardElements-supplier-content">
                    <TableResponsive
                        columns={columnTitle} 
                        data={columnData} 
                        handleClick={handleItemClick}
                        operationStatus= {type === "requestQuote" ? "quote" : "order"}
                    />
                </div>}
            </div>
        </>
    )
}