import React, { useEffect, useState } from "react";
import { PhotoUnit } from "../PhotoUnit/PhotoUnit";

import "./UploadPhotoShared.css";


interface UploadPhotoSharedProps {
  title: string;
  subtitle: string;
  setValueImages?:React.Dispatch<React.SetStateAction<any[]>>;
  handleSendImage?: (itemImg: {name: string, url: string, fileSize: number})=>void;
  imageValues?: any[] | ArrayBuffer[];
  disabled?: boolean;
  role?: string;
  disabledImages?:boolean;
  deleteImage?: (i:number)=>void
  handleUploadArray?: (itemImg: {name: string, url: string, fileSize: number}[]) => void
}

export const UploadPhotoShared: React.FC<UploadPhotoSharedProps> = ({
  title,
  subtitle,
  imageValues=[],
  disabledImages = false,
  handleSendImage = ()=>{},
  setValueImages=()=>{},
  disabled=false,
  deleteImage=()=>{},
  handleUploadArray=()=>{},
  role
}) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [photoArray, setPhotoArray] = useState<any[]>([
    {
      name: '', 
      url: ''
    },
    {
      name: '', 
      url: ''
    },
    {
      name: '', 
      url: ''
    },
    {
      name: '', 
      url: ''
    },
    {
      name: '', 
      url: ''
    }
  ]);
  
  const handlePhotoArrayChange = async (idx: number, dataImg: string | ArrayBuffer | null, name: string, fileSize: number) => {
    if(dataImg!==null) {
      let tmp: any[] = [...photoArray];
      // let index = photoArray.findIndex((t => t.id === (productNumber+1)));
      // console.log("🚀 ~ file: Quotation.tsx ~ line 150 ~ handlePhotoChange ~ tmp", tmp, index)
      let photoTmp = {...tmp[idx]};
      // console.log("🚀 ~ file: UploadPhoto.tsx ~ line 57 ~ handlePhotoArrayChange ~ photoTmp", photoTmp)
      photoTmp.url = dataImg;
      photoTmp.name = name;
      photoTmp.fileSize = fileSize/1024;
      // console.log("🚀 ~ file: UploadPhoto.tsx ~ line 36 ~ handlePhotoArrayChange ~ photoTmp",  photoTmp)
      tmp[idx] = photoTmp;
      // console.log("🚀 ~ file: UploadPhoto.tsx ~ line 62 ~ handlePhotoArrayChange ~ tmp", tmp)
      // console.log(tmp[index]);
      setPhotoArray(tmp);
      // let imagesTmp: any[] = photoArray;
      // imagesTmp[idx] = [...tmp];
      // console.log("🚀 ~ file: UploadPhoto.tsx ~ line 67 ~ handlePhotoArrayChange ~ imagesTmp", imagesTmp)
      // setValueImages((prev)=> imagesTmp);
      handleUploadArray(tmp);
      await handleSendImage(photoTmp);
    } else {
      await deleteImage(idx)
    }
  }

  // cargando array local de fotos
  useEffect(() => {
    setLoading(true)
    if(imageValues.length>0 && imageValues.length<=5) {
      let tmp: any[] = photoArray;
      imageValues.forEach((i, idx) => {
        tmp[idx] = i;
      });
      setPhotoArray(tmp);
    }
    setLoading(false)
  }, []);


  return (
    <div className="uploadphotoShared box-shadow-card" role={role}>
      <div className="uploadphotoShared-container">
       {title.length>0 && <h1 className="paragraph-header uploadphotoShared-title">{title}</h1>}
       {subtitle.length>0 && <p className="paragraph">{subtitle}</p>}

       <div className="uploadphotoShared-data box-shadow-card">
            <div className="uploadphotoShared-data-item">
                <div className="tinytext-header">Razón social</div>
                <div className="paragraph-header">DeChinaAPeru.com EIRL</div>
            </div>
            <div className="uploadphotoShared-data-item">
                <div className="tinytext-header">RUC</div>
                <div className="paragraph-header">20604397397</div>
            </div>
            <div className="uploadphotoShared-data-row">
                <div className="uploadphotoShared-data-item">
                    <div className="tinytext-header">Email</div>
                    <div className="paragraph-header">admin@dechinaaperu.com</div>
                </div>
                <div className="uploadphotoShared-data-item">
                    <div className="tinytext-header">Teléfono</div>
                    <div className="paragraph-header">+51 976616514</div>
                </div>
            </div>
            <div className="uploadphotoShared-data-item">
                <div className="tinytext-header">Dirección fiscal</div>
                <div className="paragraph-header">Calle Tamarix 284, Santiago de Surco, Lima - Perú</div>
            </div>
       </div>

        <div className="uploadphotoShared-photos">
          {!loading && photoArray.map((item, idx) => (
            <PhotoUnit 
              idx={idx}
              key={idx} 
              disabled={disabled}
              imageSrc={item.url}
              setImageData={handlePhotoArrayChange} 
              role='photo-unit__'/>
          ))}
        </div>
      </div>
    </div>
  );
};
