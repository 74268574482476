import React, { useEffect, useState } from "react";

/** importando modals */
import { Modal } from '../Modal/Modal';

/** importando css */
import "./ModalCulqi.css"
import { CreditCard } from "../../CreditCard/CreditCard";
import { ModalProcessPay } from "../ModalProcessPay/ModalProcessPay";

/** declarando interface */ 
interface ModalCulqiProps {
    showModal: boolean; // estado boolean que cotrola el modal 
    setShowModal: (e: boolean) => void; // medoto para modificar la variable showModal
    concepto?: String
    price: number,
    email: string,
    currency: string;
    onClick: (token: string) => void;
    buttonText?: string;
    loading: boolean;
}

/** componente modal cotizacion
 * renderiza el modal 
 */
export const ModalCulqi: React.FC<ModalCulqiProps> = ({
    showModal,
    setShowModal,
    price,
    email,
    currency,
    onClick,
    buttonText = "Pagar",
    loading,
    concepto=""
}) => {

    return (
        <>{!loading ?
            <Modal 
                title={'Pasarela de pago'} 
                subTitle={''} 
                show={showModal} 
                setShow={setShowModal} 
                className="modal-culqi">
                <div className="modal-culqi-container">
                  {concepto && concepto.length>0 && <div className="modal-culqi-row">
                        <div className="paragraph-header">Concepto: </div>
                        <div className="paragraph-header modal-culqi-colorText">{concepto}</div>
                    </div>}
                    <CreditCard
                        price={price}
                        email={email}
                        currency={currency}
                        onClick={onClick}
                    />
                </div>
            </Modal> : <ModalProcessPay show={showModal} setShow={setShowModal} loading={loading}  />
        }</>
    )
}
