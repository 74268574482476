import React, { useEffect, useState } from "react";

/** importando modals */
import { Modal } from '../Modal/Modal';

/** importando css */
import "./ModalCulqiDetail.css"
import { CreditCard } from "../../CreditCard/CreditCard";
import { ModalProcessPay } from "../ModalProcessPay/ModalProcessPay";
import { ServiceInterface } from "../../../interfaces/Service";
import { Rating } from "../../Rating/Rating";
import { CityCountryDisplay } from "../../CityCountryDisplay/CityCountryDisplay";
import { VerifyBlack } from "../../../icons/solid/VerifyBlack";
import { VerifyBlue } from "../../../icons/solid/Verify_blue";
import { QuotationContainerSharedInterface } from "../../../interfaces/QuotationContainerSharedInterface";
import { FormatDateMinString } from "../../../utils/formatDate";
import { containerSharedInterface } from "../../../interfaces/containerSharedInterface";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";

/** declarando interface */ 
interface ModalCulqiDetailProps {
    showModal: boolean; // estado boolean que cotrola el modal 
    setShowModal: (e: boolean) => void; // medoto para modificar la variable showModal
    price: number,
    email: string,
    currency: string;
    onClick: (token: string) => void;
    buttonText?: string;
    loading: boolean;
    service: ServiceInterface;
    quotationContainerShared: QuotationContainerSharedInterface;
    containerSelected: containerSharedInterface;
}

/** componente modal cotizacion
 * renderiza el modal 
 */
export const ModalCulqiDetail: React.FC<ModalCulqiDetailProps> = ({
    showModal,
    setShowModal,
    price,
    email,
    currency,
    onClick,
    buttonText = "Pagar",
    loading,
    service,
    quotationContainerShared,
    containerSelected
}) => {

    const verify = true;
    const numberVerify = 2;

    return (
        <>{!loading ?
            <Modal 
                title={'Pasarela de pago'} 
                subTitle={''} 
                show={showModal} 
                setShow={setShowModal} 
                className="modal-culqi-detail-content">
                <div className="modal-culqi-detail-container">
                    <div className="modal-culqi-detail-content_">
                        <div className="modal-culqi-detail">
                            <div className="small-medium modal-culqi-detail-title">Detalle de pago</div>
                            <div className="modal-culqi-firt-row_header">
                                <div className="modal-culqi-firt-row-header">
                                    <div className='modalLateralcontainer-firt--img'>
                                        <img 
                                            className='modalLateralcontainer-firt-img'
                                            src={containerSelected.containerImage}/>
                                    </div>
                                    <div className='paragraph-header cardContainer-fact-service-title'>
                                        {containerSelected.containerName}
                                    </div>
                                    {/* <div className="cardContainer-fact-service-check">
                                        {verify && numberVerify <= 2 ? 
                                        <VerifyBlack onClick={()=>{}}/> : 
                                        <VerifyBlue onClick={()=>{}}/>}
                                    </div> */}
                                </div>
                                <div className="modal-culqi-firt-row-header-detail">
                                    <Rating rating={service ? service?.rating : 4.5} /> 
                                    <CityCountryDisplay
                                        city={""}
                                        country={service ? service?.country.alpha2Code : ''}
                                        flagImg={service ? service.country.imgFlag : ''}
                                    />
                                </div>
                            </div>
                            <div className="modal-culqi-column">
                                <div className="modal-culqi-firt-column">
                                    <div className="smalltext-header">Dirección de recibo de mercadería</div>
                                    <div className="smalltext">{containerSelected.originAddress}</div>
                                </div>
                                <div className="modal-culqi-firt-column">
                                    <div className="smalltext-header">Origen</div>
                                    <div className="smalltext">{quotationContainerShared.origin}</div>
                                </div>
                                <div className="modal-culqi-firt-column">
                                    <div className="smalltext-header">Destino</div>
                                    <div className="smalltext">{quotationContainerShared.destination}</div>
                                </div>
                                <div className="modal-culqi-firt-column">
                                    <div className="smalltext-header">Recibe hasta</div>
                                    <div className="smalltext">
                                        {FormatDateMinString(containerSelected.receiveDate.toDate())}  
                                        <span className="smalltext-header">{` (Faltan ${formatDistanceToNow(containerSelected.receiveDate.toDate(), {locale: es})})`}</span>
                                    </div>
                                </div>
                                <div className="modal-culqi-firt-column">
                                    <div className="smalltext-header">Llegada estimada</div>
                                    <div className="smalltext">{FormatDateMinString(quotationContainerShared.arrivalDate)}</div>
                                </div>
                                <div className="modal-culqi-firt-column">
                                    <div className="smalltext-header">Entrega en</div>
                                    <div className="smalltext">{quotationContainerShared.deliveryAddress}</div>
                                </div>
                            </div>
                            <div className="modal-culqi-firt-row modal-culqi-firt-title">
                                <div className="smalltext-header">SERVICIO DE TRANSPORTE ALL-IN</div>
                            </div>
                            {/* <div className="modal-culqi-firt-row modal-culqi-firt-row_">
                                <div className="smalltext">Total: 450 kg ; 0.7 m3</div>
                                <div className="smalltext">0.7 m3 es igual a 1 m3</div>
                            </div> */}
                            <div className="modal-culqi-firt-row modal-culqi-firt-row_">
                                <div className="smalltext">
                                    Total: { Number(quotationContainerShared.cargoWeight).toFixed(1)} kg ; {Number(quotationContainerShared.cargoVolume).toFixed(1)} m³
                                </div>
                                {Number(quotationContainerShared.cargoVolume) % 1 !== 0  &&(
                                    // Si tiene decimales
                                    <div className="smalltext">
                                        {Number(quotationContainerShared.cargoVolume).toFixed(1)+"m³ se redondea a "+quotationContainerShared.estimatedVolume} m³
                                    </div>
                                )}
                            </div>
                            <div className="modal-culqi-firt-row modal-culqi-firt-row_">
                                <div className="small-subheader">Total a pagar:</div>
                                <div className="modal-culqir-firt-row modal-culqi-firt-row_">
                                    <div className="medium-header">{quotationContainerShared.price}</div>
                                    <div className="smalltext">USD</div>
                                </div>
                            </div>
                            <div style={{color: "#EB5757"}} className="smalltext">
                                *Incluye transporte y desaduanaje, no incluye los impuestos de la mercancia.
                            </div>
                        </div>

                        <div className="modal-culqi-checkout">
                            <div className="modal-culqi-checkout-header">
                                <div className="big-header modal-culqi-checkout-title">Reserva</div>
                                {/* <div className="paragraph">Separa tu servicio en Arkabia. <span>Paso 2 de 2</span></div> */}
                                <div className="paragraph">Separa tu espacio en el contenedor.</div>
                            </div>
                            <CreditCard
                                price={price}
                                email={email}
                                currency={currency}
                                onClick={onClick}
                            />
                        </div>
                    </div>
                </div>
            </Modal> : <ModalProcessPay show={showModal} setShow={setShowModal} loading={loading}  />
        }</>
    )
}
