import React, { useEffect, useState } from "react";

import "./QuoteContainerShared.css";

import { QuotationContainerSharedInterface } from "../../../interfaces/QuotationContainerSharedInterface";
import { FormatDate, FormatDateMinString } from "../../../utils/formatDate";
import { VerifyBlack } from "../../../icons/solid/VerifyBlack";
import { VerifyBlue } from "../../../icons/solid/Verify_blue";
import { Rating } from "../../Rating/Rating";
import { CityCountryDisplay } from "../../CityCountryDisplay/CityCountryDisplay";
import { ServiceInterface } from "../../../interfaces/Service";
import { GetServiceDetail } from "../../../Services/Service.service";
import { containerSharedDBInterface } from "../../../interfaces/containerSharedInterface";
import { GetContainerSharedById } from "../../../Services/containerShared.service";

interface QuoteContainerSharedProps {
   quotationContainerShared: QuotationContainerSharedInterface;
}

export const QuoteContainerShared: React.FC<QuoteContainerSharedProps> = ({
    quotationContainerShared
}) => {

    const [service, setService] = useState<ServiceInterface>({
        id: '',
        active: false,
        basePrice: 0,
        city: '',
        companyId: '',
        companyName: '',
        country: {
          alpha2Code: '', 
          imgFlag: '',
          name: ''
        },
        createdAt: new Date(),
        description: '',
        faq: [],
        images: [],
        numComments: 0, 
        rating: 0,
        serviceType: '',
        serviceTypeLabel: '',
        status: 'published',
        title: '',
        updatedAt: new Date(),
        verify: false,
        numberVerify: 1
      });
    const [containerShared, setContainerShared] = useState<containerSharedDBInterface>();

    const [loading, setLoading] = useState<boolean>(false);

    // console.log(quotationContainerShared)

    const GetServiceId = async (serviceId: string) => {
        setLoading(false);
        if(!loading) {
            setLoading(true);
            try {
                const response:any = await GetServiceDetail(serviceId);
                // setServiceDetail(response[0]);
                setService(response);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    }

    const GetContainerShared = async () => {
        try {
            const response = await GetContainerSharedById(quotationContainerShared.containerSharedId);
            console.log(response);
            setContainerShared(response);
        } catch (error) {
            console.log("🚀 ~ GetContainerShared ~ error:", error);
        }
    }

    useEffect(() => {
        GetServiceId(quotationContainerShared.serviceId);
        GetContainerShared();
    }, [])

    return (
        <div className="quoteContainerShared">
            <div className="box-shadow-card quoteContainerShared-container">
                <div className="small-bold">Detalles del servicio</div>

                <hr className="quoteContainerShared-line"/>

                <div className="quoteContainerShared-first">
                    <div className="quoteContainerShared-first-row">
                        <div className="quoteContainerShared-first-row-header">
                            <div className='quoteContainerShared-first--img'>
                                <img 
                                    className='quoteContainerShared-first-img'
                                    src={service && service.companyLogo}/>
                            </div>
                            <div className='paragraph-header quoteContainerShared-fact-service-title'>
                                {service && service.companyName}
                            </div>
                            <div className="quoteContainerShared-fact-service-check">
                                {service.verify && service.numberVerify <= 2 ? 
                                <VerifyBlack onClick={()=>{}}/> : 
                                <VerifyBlue onClick={()=>{}}/>}
                            </div>
                        </div>
                        <div className="quoteContainerShared-first-row-header-detail">
                            <Rating rating={service ? service?.rating : 4.5} /> 
                            <CityCountryDisplay
                                city={""}
                                country={service ? service?.country.alpha2Code : ''}
                                flagImg={service ? service.country.imgFlag : ''}
                            /> 
                        </div>
                    </div>
                </div>
                <div className="quoteContainerShared-first-detail">
                    <div className="quoteContainerShared-firt-column">
                        <div className="smalltext-header">Dirección de recibo de mercadería</div>
                        <div className="smalltext">{containerShared?.originAddress}</div>
                    </div>
                    <div className="quoteContainerShared-firt-column">
                        <div className="smalltext-header">Origen</div>
                        <div className="smalltext">{quotationContainerShared.origin}</div>
                    </div>
                    <div className="quoteContainerShared-firt-column">
                        <div className="smalltext-header">Destino</div>
                        <div className="smalltext">{quotationContainerShared.destination}</div>
                    </div>
                    <div className="quoteContainerShared-firt-column">
                        <div className="smalltext-header">Recibe hasta</div>
                        <div className="smalltext">{FormatDateMinString(containerShared?.receiveDate.toDate() || new Date())}</div>
                    </div>
                    <div className="quoteContainerShared-firt-column">
                        <div className="smalltext-header">Entrega en</div>
                        <div className="smalltext">{quotationContainerShared.deliveryAddress}</div>
                    </div>
                    <div className="quoteContainerShared-firt-column">
                        <div className="smalltext-header">Llegada estimada</div>
                        <div className="smalltext">{FormatDateMinString(new Date(quotationContainerShared.arrivalDate))}</div>
                    </div>
                </div>

                <hr className="quoteContainerShared-line"/>
                
               {containerShared && <div className="quoteContainerShared-first-detail">
                    <div className="quoteContainerShared-firt-row quoteContainerShared-firt-title">
                        <div className="smalltext-header">ESCALA DE PRECIOS</div>
                        <div className="smalltext-header">USD por m³</div>
                    </div>
                    <div className="quoteContainerShared-firt-row">
                        <div className="smalltext">De 0 a 1 m³</div>
                        <div className="smalltext">{containerShared.rateUpTo1CubicMeter}</div>
                    </div>
                    <div className="quoteContainerShared-firt-row">
                        <div className="smalltext">Mayor a 1 hasta 5 m³</div>
                        <div className="smalltext">{containerShared.rateFor1To5CubicMeters}</div>
                    </div>
                    <div className="quoteContainerShared-firt-row">
                        <div className="smalltext">Mayor 5 m³</div>
                        <div className="smalltext">{containerShared.rateAbove5CubicMeters}</div>
                    </div>
                </div>}

                <hr className="quoteContainerShared-line"/>

                <div className="quoteContainerShared-first-detail">
                    <div className="quoteContainerShared-firt-row quoteContainerShared-firt-title">
                        <div className="smalltext-header">SERVICIO DE TRANSPORTE ALL-IN</div>
                    </div>
                    <div className="quoteContainerShared-firt-row">
                        <div className="smalltext">
                            Total: {Number(quotationContainerShared.cargoWeight).toFixed(1)} kg ; {Number(quotationContainerShared.cargoVolume).toFixed(1)} m³
                        </div>
                        {Number(quotationContainerShared.cargoVolume) % 1 === 0 ? (
                            // Si es número entero
                            <div className="smalltext">
                                {quotationContainerShared.cargoVolume} m³
                            </div>
                        ) : (
                            // Si tiene decimales
                            <div className="smalltext">
                                {Number(quotationContainerShared.cargoVolume).toFixed(1)+"m³ se redondea a "+
                                quotationContainerShared.estimatedVolume} m³
                            </div>
                        )}
                    </div>
                </div>

                <div className="quoteContainerShared-first-detail_">
                    <div className="quoteContainerShared-firt-row quoteContainerShared-firt-row_">
                        <div className="small-subheader">Total:</div>
                        <div className="quoteContainerShared-firt-row quoteContainerShared-firt-row_">
                            <div className="medium-header">{quotationContainerShared.price }</div>
                            <div className="smalltext"> USD</div>
                        </div>
                    </div>
                    <div style={{color: "#EB5757"}} className="smalltext">
                        *Incluye transporte y desaduanaje, no incluye los impuestos de la mercancia.
                    </div>
                </div>
            </div>


            {/* <div className="quoteContainerShared-container">
                <div className='rowQuote-header-container rowQuote-header--pointer paragraph-header'> 
                    Gastos de importación
                </div>
                <div className="rowQuote-body">
                    <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Peso</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{quotationContainerShared.cargoWeight} kg</div>
                    </div>
                    <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Volumen</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{quotationContainerShared.cargoVolume} m³</div>
                    </div>
                    <div className="rowQuote_black" >
                        <div className="paragraph" style={{color: "var(--white)"}}>Transporte All-In (USD)</div>
                        <div className="paragraph" style={{color: "var(--white)"}}>{quotationContainerShared.price}</div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}