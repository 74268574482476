import React, { useEffect, useState } from "react";

// Importando componentes
import { UserActive } from "../UserActive/UserActive";
import { Status } from "../Status/Status";
import { Button } from "../Button/Button";

// Importando data
import { User } from "../../interfaces/User";
import "./UserCard.css";
import { VerifyBlack } from "../../icons/solid/VerifyBlack";
import { UserVerify } from "../UserVerify/UserVerify";
import { useSelector } from "react-redux";
import { SecondaryPlanInterface } from "../../interfaces/SecondaryPlanInterface";
import { GetSecondaryPlanById } from "../../Services/SecondaryPlanArkabia.service";


interface UserCard {
    user: User | undefined;
    myProfile?: boolean;
    showOption?: boolean;
    pending?: boolean;
    loadUser?: ()=>void;
    showVerify?: boolean;
    setShowVerify?: React.Dispatch<React.SetStateAction<boolean>>;
}   

//Recibir el setter de arreglo de productos
export const UserCard: React.FC<UserCard> = ({
    user,
    myProfile = false,
    showOption = false,
    pending = false,
    loadUser= ()=>{},
    showVerify = false,
    setShowVerify = ()=>{},
}) => {

  const {userDetail} = useSelector((state: any) => state.user);
  const {plan} = useSelector((state: any) => state.plan);
  const {subscription} = useSelector((state: any) => state.subscription);

  const [secondaryPlan, setSecondaryPlan] = useState<SecondaryPlanInterface>();

  const handleVerify = () => {
    setShowVerify(true);
  }
  const getSecondaryPlan = async () => {
    try {
      console.log("getSecondaryPlan")
      const response = await GetSecondaryPlanById(subscription.planId);
      if(response) {
        setSecondaryPlan(response);
      }
    } catch (error) {
      console.log("🚀 ~ getSecondaryPlan ~ error:", error)
      
    }
  };

  useEffect(() => {
    subscription.subscriptionType === "secondary" && getSecondaryPlan();
  }, [subscription]);


  return (
    <div className="userCard">
        <>
            { user && 
            <UserActive 
                userId={user.id?user.id:''}
                userImg={user.photoUrl?user.photoUrl:''}
                isConnected={true}
                name={user.name}
                lastname={user.lastName}
                loadUser={loadUser} 
                edit
            />
            }

            { user && <div className="userCard-detail">
                <div className="userCard-detail-first">
                    <div className="smalltext-header userCard-detail-first-text">Hola,</div>
                    <div className="userCard-detail-first-row">
                        <div className="paragraph userCard-detail-first-name">{user.name+" "+user.lastName} </div>
                        {user.isVerified && <VerifyBlack onClick={()=>handleVerify()}/> }
                    </div>
                   {user.userType?.includes("user") && subscription&& <div className="tinytext">{`Suscriptor ${subscription.subscriptionType === "primary" ? plan.title : secondaryPlan?.name}`}</div>}
                </div>
                { myProfile && <Status online={true}/>}
            </div>}
        </>

        {showVerify && user &&
          <UserVerify
            title="Cuenta verificada" 
            showVerify={user.isVerified}
            setShowVerify={setShowVerify}
          />}
          
          {/* <div className="profile-detail__row">
            <div className="profile-detail-list">
              <div>
                <div className="small-bold profile-detail-name">{user.name+" "+user.lastName} 
                  {user.isVerified && <VerifyBlack onClick={()=>handleVerify()}/> }
                </div>

                {user.position && user.businessName && <div className="paragraph profile-detail-nickname">{user.position+" "+ "en" +" "+user.businessName}</div>}
                {!user.position && user.businessName && <div className="paragraph profile-detail-nickname">{"Trabaja en" +" "+user.businessName}</div>}
              </div> */}
              {/* {!user.request && business && business.length>0 && <div className="paragraph profile-detail-business">{business}</div>}
              <div className="paragraph profile-detail-nickname">{user.userName}</div> */}
              {/* { user.request && <div className="paragraph profile-detail-nickname-request">Aún no acepta la solicitud</div> } */}
              {/* { myProfile && <Status online={true}/>}
              { (showOption && pending) && <div className="profile-detail-buttons">
                <Button content="Rechazar" color="red" size="extra-small" />
                <Button content="Aceptar" color="green-2" size="extra-small" />
              </div>}
              { (showOption && !pending) &&
                <div className="profile-detail-buttons">
                  <Button content="Eliminar" color="red" size="extra-small" />
                </div>
              }
            </div>
          </div> */}
          {/* {showVerify && 
          <UserVerify
            title="Cuenta verificada" 
            showVerify={user.isVerified}
            setShowVerify={setShowVerify}
          />} */}
    </div>
  );
};
