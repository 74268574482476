import React, { useEffect, useState } from "react";
import "./ImportationSubstructureConsignee.css";
import { Button, ButtonIcon, Dropdown, TextField } from "..";
import { Consignee } from "../../interfaces/Consignee";
import { ConsigneeError } from "../../interfaces/ConsigneeError";
import { CardContent } from "../CardContent/CardContent";
import { ButtonEditChange } from "../ButtonEditChange/ButtonEditChange";
import { Edit } from "../../icons/outline/Edit";

interface ImportationSubstructureConsigneeProps {
  consigneeElements: any[];
  consignee: Consignee;
  consigneeSelected: string
  setConsignee: React.Dispatch<React.SetStateAction<Consignee>>;
  personOrCompanyElements: string[];
  documentTypeElements: string[];
  errors?: ConsigneeError,
  handleSaveConsignee: React.MouseEventHandler<HTMLButtonElement>;
  handleConsigneeSelected: (idx: number) => void;
  disabled?: boolean;
  setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  detail: any;
}

export const ImportationSubstructureConsignee: React.FC<ImportationSubstructureConsigneeProps> =
  ({
    consigneeElements,
    consignee,
    setConsignee,
    personOrCompanyElements,
    documentTypeElements,
    handleSaveConsignee,
    errors={
      typeIt: {
        message: '',
        status: false,
    },
    typeDocument: {
        message: '',
        status: false,
    }, 
    itNumber: {
        message: '',
        status: false,
    },
    companyName: {
        message: '',
        status: false,
    },
    fiscalAddress: {
        message: '',
        status: false,
    },
    email: {
        message: '',
        status: false,
    },
    phone: {
        message: '',
        status: false,
    },
    lastName: {
        message: '',
        status: false,
    },
    name: {
        message: '',
        status: false,
    }
    },
    handleConsigneeSelected=()=>{},
    consigneeSelected,
    disabled = false,
    setDisabled = ()=>{},
    detail
  }) => {

    // const [consigneSelected, setConsigneeSelected] = useState<string>('');
    const [addConsignee, setAddConsignee] = useState<boolean>(false);
    
    const handleTypePersonChange = (value: string | number ) => {
      setConsignee((prev: any) => {
        return {
          ...prev,
          typeDocument: value,
          typeIt: value === 'Persona jurídica' ? 'RUC': ''
        }
      })
    }

    const handleNameChange = (value: string | number ) => {
      setConsignee((prev: any) => {
        return {
          ...prev,
          name: value
        }
      })
    }

    const handleLastNameChange = (value: string | number ) => {
      setConsignee((prev: any) => {
        return {
          ...prev,
          lastName: value
        }
      })
    }

    const handleRUCChange = (value: string | number ) => {
      if(Number(value)>= 0 && Number(value) <= 99999999999) {
        setConsignee((prev: any) => {
          return {
            ...prev,
            itNumber: value
          }
        });
      }
    }

    const handleBusinessNameChange = (value: string | number ) => {
      setConsignee((prev: any) => {
        return {
          ...prev,
          companyName: value
        }
      })
    }

    const handleFiscalAddressChange = (value: number ) => {
      setConsignee((prev: any) => {
        return {
          ...prev,
          fiscalAddress: value
        }
      })
    }

    const handleTypeDocChange = (value: string | number ) => {
      setConsignee((prev: any) => {
        return {
          ...prev,
          typeIt: value
        }
      })
    }

    const handleNumDocChange = (value: number ) => {
      let tmp = consignee.itNumber;
      switch (consignee.typeIt ) {
        case 'RUC':
          if(value>=0 && value<99999999999) {
            tmp = value;
          }
          break;
        case 'DNI':
          if(value>=0 && value<99999999) {
            tmp = value;
          }
          break;
        default:
          tmp = value;
          break;
      }
      setConsignee((prev: any) => {
        return {
          ...prev,
          itNumber:  tmp
        }
      })
    }

    const handleEmailChange = (value: string | number ) => {
      setConsignee((prev: any) => {
        return {
          ...prev,
          email: value
        }
      })
    }

    const handlePhoneChange = (value: string | number ) => {
      setConsignee((prev: any) => {
        return {
          ...prev,
          phone: value
        }
      })
    }

    const handleConsigneeChange = (value: string) => {
        setAddConsignee(value === 'Agregar consignatario');
    }

    return ( 
      <CardContent className="importation-substructure-consignee-first">
        <div className="paragraph-header">Consignatario</div>
        <p className="paragraph">
          {detail}
        </p>

       { consigneeElements.length>1 && 
        <div className="importation-substructure-consignee_dropdown">
          <Dropdown
            title={consigneeSelected}
            value={consigneeSelected}
            elements={consigneeElements}
            setValue={(e:any)=>handleConsigneeChange(e)}
            //setValue={(e:any) =>{setConsigneeSelected(e)}}
            setIdx={(e:any)=> handleConsigneeSelected(e)}
            roleText='consignee-importation-substructure-consignee'
            disabled={consigneeElements.length>1 ? false : true}
          />
        </div>
       }
        {/* <p className="paragraph">Ingresar los datos</p> */}
        
        { (consigneeElements.length === 1 || addConsignee) &&  <Dropdown
          title="Entidad"
          elements={personOrCompanyElements}
          value={consignee.typeDocument}
          setValue={(e: any) =>handleTypePersonChange(e)}
          roleText='typePerson-importation-substructure-consignee'
          error={errors.typeDocument}
          disabled={false}
        />}

        {consignee.typeDocument === 'Persona jurídica' &&     
          <div className="importation-substructure-consignee-inputs">
            <TextField
              title="RUC"
              placeholder=" "
              value={consignee.itNumber}
              onChange={(e:any) =>handleRUCChange(e.target.value)}
              type='number'
              role='ruc-importation-substructure-consignee'
              error={errors.itNumber}
              disable={disabled}
            />
          
            <TextField
              title="Razón social de la empresa"
              placeholder=" "
              value={consignee.companyName}
              onChange={(e:any) => handleBusinessNameChange(e.target.value)}
              role='title-importation-substructure-consignee'
              error={errors.companyName}
              disable={disabled}
            />
          </div> 
        }

        {consignee.typeDocument === 'Persona natural' &&     
          <div className="importation-substructure-consignee-inputs">
          <TextField
              title="Nombre del declarante"
              placeholder=" "
              value={consignee.name}
              onChange={(e:any) =>handleNameChange(e.target.value)}
              role='ruc-importation-substructure-consignee'
              error={errors.name}
              disable={disabled}
            />

            <TextField
              title="Apellido del declarante"
              placeholder=" "
              value={consignee.lastName}
              onChange={(e:any) => handleLastNameChange(e.target.value)}
              role='title-importation-substructure-consignee'
              error={errors.lastName}
              disable={disabled}
            />
          </div> 
        }

        {consignee.typeDocument === 'Persona jurídica' &&  
          <div className="importation-substructure-consignee-inputs">
            <TextField
              title="Dirección fiscal"
              placeholder=" "
              value={consignee.fiscalAddress}
              onChange={(e:any) => handleFiscalAddressChange(e.target.value)}
              role='title-importation-substructure-consignee'
              error={errors.fiscalAddress}
              disable={disabled}
            />
          </div>
        }

        { consignee.typeDocument === 'Persona natural' && 
          <div className="importation-substructure-consignee-inputs">
            <Dropdown
              title="Tipo de documento"
              elements={documentTypeElements}
              value={consignee.typeIt}
              setValue={(e:any) => handleTypeDocChange(e)}
              roleText='typeDoc-importation-substructure-consignee'
              error={errors.typeIt}
              disabled={disabled}
            />
            <TextField
              title={"Número de "+(consignee.typeIt.length>0?consignee.typeIt: 'documento')}
              placeholder=" "
              value={consignee.itNumber}
              onChange={(e:any) => handleNumDocChange(e.target.value)}
              type={(consignee.typeIt === 'RUC' || consignee.typeIt === 'DNI') ? 'number': 'text'}
              role='numDoc-importation-substructure-consignee'
              error={errors.itNumber}
              disable={disabled}
            />
          </div> 
        }

        <div className="importation-substructure-consignee-inputs">
          <TextField
            title="Email"
            placeholder=" "
            type="email"
            value={consignee.email}
            onChange={(e:any) => handleEmailChange(e.target.value)}
            role='email-importation-substructure-consignee'
            error={errors.email}
            disable={disabled}
          />
          <TextField
            title="Teléfono"
            placeholder=" "
            value={consignee.phone}
            onChange={(e: any) => handlePhoneChange(e.target.value)}
            type='tel'
            role='phone-importation-substructure-consignee'
            error={errors.phone}
            disable={disabled}
          />
        </div> 

        <div className="importation-substructure-consignee-btn">
        {disabled && 
            <ButtonEditChange
              onClickEdit={()=>setDisabled(!disabled)}
              edit={!disabled}
              setEdit={setDisabled}
              add={false}
              onClickCancel={()=>{}}
              onClickComplete={() => {}}
              onClickSave={() => {}}
              role='btn-area-importation-SCAAHI'/>
          }
          <div className="importation-substructur--error tinytext-header"></div>
          {!disabled &&
          <Button
            size="large"
            content={`${consignee.id? 'Guardar':'Actualizar'} consignatario`}
            color="black-75"
            onClick={handleSaveConsignee}
            role='btn-importation-substructure-consignee'/>}
        </div>
      </CardContent>
    );
  };
