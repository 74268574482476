import React, { useEffect, useState, useCallback, useRef } from "react";

// Importando Store
import type { AppDispatch } from "../../../Store/Store";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import debounce from "lodash.debounce";

/** importando componentes */
import { OperationHeader } from "../../../components";
import { Multimedia } from "../../../components/Multimedia/Multimedia";
import { ImportPhasesCard } from "../../../components/ImportPhasesCard/ImportPhasesCard";
import { Chat } from "../../../components/Chat/Chat";
import { MessageTopbar } from "../../../components/MessageTopBar/MessageTopbar";
import { HeaderCustomsPhases } from "../../../components/HeaderCustomsPhases/HeaderCustomsPhases";
import { ButtonBadget } from "../../../components/ButtonBadget/ButtonBadget";


/** Importando Interfaces */
import { Consignee } from "../../../interfaces/Consignee";
import { InvoiceTranslation} from "../../../interfaces/InvoiceTranslation";
import { ProductDeclareInterface } from "../../../interfaces/ProductDeclare";
import { OrderInterface } from "../../../interfaces/OrderInterface";
import { OperationInterface } from "../../../interfaces/Operation";
import { QuotationDB } from "../../../interfaces/Quotation";
import { RequestQuoteInterface } from "../../../interfaces/RequestQuote";
import { BillingReviewInterface } from "../../../interfaces/BillingReviewInterface";
import { CargoShippet } from "../../../interfaces/CargoShippet";
import { DocumentationInterface } from "../../../interfaces/Documentation";
import { ConsigneeError } from "../../../interfaces/ConsigneeError";
import { ArrivalControlChannel } from "../../../interfaces/ArrivalControlChannelInterface";
import { Company } from "../../../interfaces/Company";
import { ChatInterface } from "../../../interfaces/ChatInterface";

/** Importando Slice */
import { GetOperationDetail } from "../../../Slices/Operation";
import { GetQuotationById } from "../../../Slices/Quotation";

/** Importando constantes */
import { CUSTOMS, OPERATIONS, ORDER,  CHAT, DETAIL,  COUNTRY_FLAG_BASE} from "../../../Consts/BaseUrl";
import { phaseTypeService } from "../../../Consts/phasesImport";
import { CommercialLevel } from "../../../Consts/CommercialLevel";
import { ChannelControls } from "../../../Consts/ChannelControls";

// importando servicios
import { CreateBillingReview, GetBillingReview, UpdateBillingReview } from "../../../Services/BillingReview.service";
import StoreService from "../../../Services/Store.service";
import { GetOrderDetail, SetOrder, UpdateOrder } from "../../../Services/Order.service";
import { GetCargoShippetId } from "../../../Services/CargoShippet";
import { getDocumentationById } from "../../../Services/Documentation.service";
import { GetAllConsigneeByUserId, CreateConsignee, UpdateConsignee } from "../../../Services/Consignee.service";
import { CreateInvoiceTranslation, GetInvoiceTranslationById, UpdateInvoiceTranslation } from "../../../Services/InvoiceTranslation.service"
import { FinishService } from "../../../Services/Service.service";
import { GetRequestQuoteById } from "../../../Services/RequestQuote.service";
import { GetCompanyById } from "../../../Services/Company.service";
import { CreateArrivalControlChannel, GetArrivalControlChannel, UpdateArrivalControlChannel } from "../../../Services/ArrivalControlChannel.service";

/** Importando utilidades */
import { ImportTimelineStages } from "../../../@types/timelineStagesTypes";
import { ValidateField } from "../../../utils/ValidateField";
import { FormatDate } from "../../../utils/formatDate";

/** Importando Estilo */
import './CustomsAgency.css';
import { GetChatById } from "../../../Services/Chat.service";
import { sendProviderMessageNotification, sendUserMessageNotification } from "../../../Services/Whatsapp.service";
import { GetUserById } from "../../../Services/User.service";
import { MessageBlack } from "../../../icons/outline/MessageBlack";
import { PhaseCustomsAgency } from "../../../components/PhaseCustomsAgency/PhaseCustomsAgency";
import { GetQuotationContainerShared } from "../../../Services/QuotationcontainerShared.service";
import { QuotationContainerSharedInterface } from "../../../interfaces/QuotationContainerSharedInterface";
import { ServiceInterface } from "../../../interfaces/Service";

export const CustomsAgencyPage: React.FC<{}> = () => {

    const navigation = useNavigate();
    const params = useParams();

    const dispatch = useDispatch<AppDispatch>();

    const { operationList } = useSelector((state: any) => state.operation);
    const { userDetail } = useSelector((state: any) => state.user);

    const ref = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLDivElement>(null);
   
    const [stepTitle, setStepTitle] = useState<string[]>([]);
    const [phase, setPhase] = useState(-1); // fase 0 chat;
    const [mobileMode, setMobileMode] = useState(window.innerWidth<=880);

    const [loading, setLoading] = useState<boolean>(false);
    const [orderStatus, setOrderStatus] = useState<ImportTimelineStages>(2);

    const [company, setCompany] = useState<Company>();
    const [showEdit, setShowEdit] = useState(false);
    const [showchat, setShowChat] = useState(true);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    // modals
    const [showMenu, setShowMenu] = useState(true);
 
    // useState search
    const [searchText, setSearchText] = useState<string>('');

    // state consignee
    const [consigneeSelected, setConsigneeSelected] = useState<string>();
    const [consigneeList, setConsigneeList] = useState<Consignee[]>([]);
    const [consignee, setConsignee] = useState<Consignee>({
        companyName: '',
        fiscalAddress: '',
        itNumber: undefined,
        lastName: '',
        name: '',
        typeDocument: 'Persona jurídica',
        typeIt: 'RUC',
        userId: '',
        email: '',
        phone: undefined,
        status: true,
        createdAt: new Date(),
        updatedAt: new Date()
    });
    
    const [consigneeError, setConsigneeError] = useState<ConsigneeError>({
        typeIt: {
            message: '',
            status: false,
        },
        typeDocument: {
            message: '',
            status: false,
        }, 
        itNumber: {
            message: '',
            status: false,
        },
        companyName: {
            message: '',
            status: false,
        },
        fiscalAddress: {
            message: '',
            status: false,
        },
        email: {
            message: '',
            status: false,
        },
        phone: {
            message: '',
            status: false,
        },
        lastName: {
            message: '',
            status: false,
        },
        name: {
            message: '',
            status: false,
        }
    });

    // Operation
    const [operationDetail, setOperationDetail] = useState<OperationInterface>();

    // almacena la orden de la base de datos
    const [orderDetail, setOrderDetail] = useState<OrderInterface>({
        activePhase: 0,
        companyId: '',
        companyName: '',
        country: {
            alpha2Code: '',
            imgFlag: '',
            name: ''
        },
        createdAt: undefined,
        paid: false,
        price: 0,
        quotationId: '',
        requestQuoteId: '',
        requirementsId: '',
        serviceId: '',
        serviceTypeId: '',
        serviceTypeLabel: '',
        planId: '',
        operationId: '', 
        status: false,
        title: '',
        type: 'Importación',
        updatedAt: new Date(),
        user: {
          id: '',
          userName: ''
        },
        number: '',
        phasesId: '',
    });
    
    // Ordenes
    // const [orders, setOrders] = useState<OrderInterface[]>([]);

    // almacena el billing que se va crear o de la base de datos
    const [billingReviewData, setBillingReviewData] = useState<BillingReviewInterface>({
        companyId: '',
        orderId: '',
        serviceId: '',
        userId: '',
        billingUrl: [],
        reviewService: {
            comment: '',
            rating: 0,
            userId: ''
        },
        reviewClient: {
            comment: '',
            rating: 0,
            userId: ''
        },
        operationId: '',
        status: false,
        createdAt: new Date(),
        updatedAt: new Date()
    });

    // state cargoShippet
    const [cargoShippet, setCargoShippet] = useState<CargoShippet>({
        arrivalDate: undefined,
        mmsi: 0,
        createdAt: new Date(),
        nameBoat: '',
        operationId: orderDetail ? orderDetail?.operationId: '',
        orderId: orderDetail ? orderDetail.id: '',
        providerId: userDetail ? userDetail.id: '',
        typeBoat: '',
        vTypeBoat: 0,
        status: true,
        updatedAt: new Date()
    });

    // document
    const [documentationData, setDocumentationData] = useState<DocumentationInterface>({
        companyId: '',
        orderId: '',
        serviceId: '',
        userId: '',
        providerId: '',
        operationId: '',
        comercialInvoice:[],
        packingList: [],
        billLading: [],
        insurancePolicy: [],
        additionDocumentation: [],
        lettering:[],
        status: false,
        createdAt: new Date(),
        updatedAt: new Date()
    })

    //chat
    const [chatdetail, setchatDetail] = useState<ChatInterface>();

    /** InvoiceTranslation */
    const [invoiceTranslation, setInvoiceTranslation] = useState<InvoiceTranslation>({
        importer: {
            typeDocument: 'Persona jurídica',
            typeIt: 'DNI',
            nameCompany: '',
            itNumber: 0,
            commercialLevel: 'Mayorista',
            name: '',
            lastName: '',
        },
        legalRepresentative: {
            declarantName: '',
            declarantLastName: '',
            position: '',
            typeIt: 'DNI',
            itNumber: 0,
        },
        provider: [{
            name: '',
            invoiceNumber: '',
            country: {
                name: '',
                alpha2Code: '',
                imgFlag: '',
            },
            city: '',
            phone: '',
            providerType: '',
            providerAddress: '',
            incoterm: '',
            currency: '',
            countryAcquisition: {
                name: '',
                alpha2Code: '',
                imgFlag: '',
            },
            paymentType: '',
            financialEntity: '',
            placeDelivery: '',
            product: []
        }],
        userId: '',
        status: true,
        createdAt: new Date(),
        updatedAt: new Date()
    });

    //arrival y canal de control
    const [arrivalControlChannel, setArrivalControlChannel] = useState<ArrivalControlChannel>({
        noticeArrival: [],
        dutiesTaxes: {
            taxableBase: {
                productCost: 0,
                internationalTransport: 0,
                sure: 0,
                total: 0,
            },
            taxes: {
                adValorem: 0,
                igv: 0,
                ipm: 0,
                perception: 0,
                total: 0,
            },
            file: [],
            exchangeRate: 0,
        },
        finalDeliveryPlace: {
            department: '',
            province: '',
            district: '',
            direction: '',
            reference: '',
        },
        controlChannel: 'No asignado todavía',
        status: true,
        userId: '',
        createdAt: new Date(),
        updatedAt: new Date()
    })

    const [invoice, setInvoice] = useState<any>(undefined);
    const [review, setReview] = useState<{raintg:  number, review: string}>({
        raintg: 0,
        review: ''
    });
   
    // state agente de carga
    const [bulkingAgent, setBuilkingAgent] = useState<string>('');

    const [quotationDetail, setQuotationDetail] = useState<QuotationDB>();
    const [requestQuote, setRequestQuote] = useState<RequestQuoteInterface>(
        {
            active: false,
            serviceId: '',
            serviceTypeLabel: '',
            status: 'draft',
            title: '',
            user: {
                id: '',
                itNumber: 0,
                name: '',
                numOperations: 0,
                typeIt: '',
                phone: '',
                rating: 0
            },
            statusProvider: 'No leída',
            country: {
                alpha2Code:'',
                imgFlag: '',
                city: ''
            },
            companyName: '',
            numServiceRating: 0,
            numServiceComments: 0,
            transport: {
                origin: {
                    city: '',
                    country: ''
                },
                destination: {
                    city: '',
                    country: ''
                },
                typeContainer: {
                    lcl: false,
                    fcl: false,
                    lclAir: false

                },
                type: undefined
            },
            customs: {
                coin: '',
                incoterm: '',
                insurance: undefined,
                regime: '',
                value: undefined,
                descriptionOperation: ''
            },
            observation: ''
        }
    );


    // use state container shared
    const [quotationContainerShared, setQuotationContainerShared] = useState<QuotationContainerSharedInterface>();

    const navigateToSection = (refDiv:React.RefObject<HTMLDivElement>) => {
        if (refDiv && refDiv.current) {
            refDiv.current.scrollIntoView({ behavior: 'smooth' });
        } 
    };

    const getInfoFromPhase = async (orderStep: number, order: OrderInterface, rq: RequestQuoteInterface | undefined ) => {
        switch (orderStep) {
            case 2:
                setBuilkingAgent(order.bulkingAgent ? order.bulkingAgent : '');
                await getConsigneeList(order.userId || "", order.consigneeId ? order.consigneeId:'');
                break;
            case 3:
                if(order.serviceTypeId === "agenciamiento-de-aduana" || order.serviceTypeId === "courier")   {
                    await getDocumentation(order.documentationId);
                } else {
                    await getBillingReviewList(order.billingReviewId);
                }                       
                break;
            case 4:
                if(order.cargoShippetId && order.cargoShippetId.length>0) {
                    await getCargoShippetData(order.cargoShippetId);
                } else {
                    setCargoShippet((prev) => ({
                        ...prev,
                        operationId: order.operationId,
                        orderId: order.id
                    }))
                }
                break;
            case 5:
                await getInvoiceTranslation(order.invoiceTranslationId || "", rq?.customs.incoterm || "", rq?.customs.coin || "");
                break;
            case 6:
                await getArrivalControlChannel(order.arrivalControlChannelId || "");
                break;
            case 7:
                await getBillingReviewList(order.billingReviewId);
                break;

            default:
                break;
        }
    }

   /** metodos */
    const handlePhaseClick = async (orderStep: number) => {
        setShowMenu(false);
        setShowChat(false);
        setPhase(orderStep);

        getInfoFromPhase(orderStep, orderDetail, requestQuote);

        if (window.innerWidth<=700) {
            setTimeout(() => {
                navigateToSection(parentRef);
            }, 300);
        }

        navigation(`/${OPERATIONS}/${params.operationId}/${ORDER}/${params.orderId}/${orderStep}`);
    }

    const handleMessageButtonClick = () => {
        setPhase(0);
        navigation(`/${CUSTOMS}/${params.customsId}/${0}`);
    }

    const handleMessageMobileClick = (id: string) => {
        navigation("/"+CHAT+"/"+DETAIL+"/"+id);
    }

    const handleMessageClick = (chatId: string) => {
        window.innerWidth <= 880 ? handleMessageMobileClick(chatId) : setShowChat(true);
        setPhase(0);
    }

    const validateConsigneeFields =  () => {
        let error:boolean = false;
        if(consignee.typeDocument === 'Persona jurídica') {
            error = ValidateField(consignee.itNumber, consignee.typeIt === 'RUC' ? 'RUC': (consignee.typeIt === 'DNI'? 'DNI': 'required')) || ValidateField(consignee.companyName, 'required') || ValidateField(consignee.fiscalAddress, 'required') || 
            ValidateField(consignee.email, 'required') || ValidateField(consignee.phone, 'number') || ValidateField(consignee.typeIt, "required");
            // setting error object
            setConsigneeError({
                    typeDocument: {
                        status: ValidateField(consignee.typeDocument, 'required'),
                        message: 'Selecciona entidad'
                    },
                    typeIt: {
                        status: ValidateField(consignee.typeIt, 'required'),
                        message: 'Ingresa de documento'
                    },
                    itNumber: {
                        status: ValidateField(consignee.itNumber, consignee.typeIt === 'RUC' ? 'RUC': (consignee.typeIt === 'DNI'? 'DNI': 'required')),
                        message: 'Ingresa número válido de ' + consignee.typeIt,
                    },
                    companyName: {
                        status: ValidateField(consignee.companyName, 'required'),
                        message: 'Ingresa razon social'
                    },
                    fiscalAddress: {
                        status: ValidateField(consignee.fiscalAddress, 'required'),
                        message: 'Ingresa una dirección'
                    },
                    email: {
                        status: ValidateField(consignee.email, 'required'),
                        message: 'Ingresa un correo electrónico'
                    },
                    phone: {
                        status: ValidateField(consignee.phone, 'number'),
                        message: 'Ingresa teléfono'
                    },
                    name: {
                        status: false,
                        message: 'Ingresa nombre del declarante'
                    },
                    lastName: {
                        status: false,
                        message: 'Ingresa apellido del declarante'
                }
            });
        } else if (consignee.typeDocument === 'Persona natural') {
            error = ValidateField(consignee.name, 'required') || ValidateField(consignee.lastName, 'required') || ValidateField(consignee.typeIt, 'required') || 
            ValidateField(consignee.itNumber, consignee.typeIt === 'RUC' ? 'RUC': (consignee.typeIt === 'DNI'? 'DNI': 'required')) || ValidateField(consignee.email, 'required') || 
            ValidateField(consignee.phone, 'required');
            setConsigneeError({
                typeDocument: {
                    status: ValidateField(consignee.typeDocument, 'required'),
                    message: 'Selecciona entidad'
                },
                typeIt: {
                    status: ValidateField(consignee.typeIt, 'required'),
                    message: 'Ingresa de documento'
                },
                itNumber: {
                    status: ValidateField(consignee.itNumber, consignee.typeIt === 'RUC' ? 'RUC': (consignee.typeIt === 'DNI'? 'DNI': 'required')),
                    message: 'Ingresa número válido de ' + consignee.typeIt,
                },
                companyName: {
                    status: false,
                    message: 'Ingresa razon social'
                },
                fiscalAddress: {
                    status: false,
                    message: 'Ingresa una dirección'
                },
                email: {
                    status: ValidateField(consignee.email, 'required'),
                    message: 'Ingresa un correo electrónico'
                },
                phone: {
                    status: ValidateField(consignee.phone, 'number'),
                    message: 'Ingresa teléfono'
                },
                name: {
                    status: ValidateField(consignee.name, 'required'),
                    message: 'Ingresa nombre del declarante'
                },
                lastName: {
                    status: ValidateField(consignee.lastName, 'required'),
                    message: 'Ingresa apellido del declarante'
                }
            });
        } 

        return error;
    }
    
    const handleSaveConsigneeClick =  async() => {
        let error = validateConsigneeFields();
        if(!error) {
            try {
                if(consignee && consignee.id) { // update consignee
                    // console.log("handleSaveConsigneeClick update");
                    setLoading(true);
                    const response:any = await UpdateConsignee({...consignee, userId: orderDetail?.user.id ? orderDetail.user.id : '', updatedAt: new Date()})
                    toast.success("¡Los datos se guardaron con éxito!");
                    setLoading(false);
                    getConsigneeList(orderDetail?.user.id ? orderDetail.user.id : '', consignee.id?consignee.id:'');
                    setIsDisabled(true);
                } else { // create a new consignee
                    setLoading(true);
                    const response:any = await CreateConsignee({...consignee, userId: orderDetail?.user.id ? orderDetail.user.id : ''});
                    toast.success("¡Los datos se guardaron con éxito!");
                    UpdateOrder({
                        id: orderDetail.id?orderDetail.id:'',
                        consigneeId: response.id,
                        updatedAt: new Date(),
                    }).then(() => {
                        setLoading(false);
                        getConsigneeList(orderDetail?.user.id ? orderDetail.user.id : '', response.id);
                        setIsDisabled(true)
                    }).catch((error) => {
                        console.log(error);
                        setLoading(false);
                    })          
                }
            } catch (error) { 
                console.log("🚀 ~ file: CustomsAgency.tsx:638 ~ handleSaveConsigneeClick ~ error:", error)
                setLoading(false);
                toast.error("Algo salio mal, por favor intentato en unos minutos");
            }     
        } else {
            toast.warning('¡Ingresa todo los campos de forma correcta!');
        }
    }
    
    // Crear Arribo y canal de control
    const handleSaveArrivalControlChannel = async () => {
        let error = false
        // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 698 ~ handleSaveArrivalControlChannel ~ error", error)
        if(!error) {
            if(orderDetail.arrivalControlChannelId && orderDetail.arrivalControlChannelId.length>0) {
                // toast.warning('¡Actualizando datos!');
                setLoading(true);
                const response:any = await UpdateArrivalControlChannel({...arrivalControlChannel, userId: orderDetail?.user.id ? orderDetail.user.id : '', updatedAt: new Date()});
                toast.success('¡Los datos se actualizaron correctamente!')
                setLoading(false);
                getArrivalControlChannel(orderDetail.arrivalControlChannelId);  
                setShowEdit(false); 
            } else {
                try {
                    // toast.info("Guardando arribo y canal de control");
                    setLoading(true);
                    const response:any = await CreateArrivalControlChannel({...arrivalControlChannel, userId: orderDetail?.user.id ? orderDetail.user.id : ''})
                    toast.success("¡Los datos se guardaron con éxito!");
                    setShowEdit(false);
                    UpdateOrder({
                        id: orderDetail.id?orderDetail.id: '',
                        arrivalControlChannelId: response.id,
                        updatedAt: new Date(),
                    }).then(()=> {
                        // clearArrivalControlFields();
                        setLoading(false);
                        // getArrivalControlChannel(orderDetail?.user.id ? orderDetail.user.id: '');
                    }).catch((error) =>{
                        // console.log(error);
                        setLoading(false);
                    })
                } catch (error) {
                    setLoading(false);
                    // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 715 ~ handleSaveArrivalControlChannel ~ error", error)
                    toast.error("Algo salio mal 🙁, por favor intentato en unos minutos");
                }
            }
        } 
    }

    // metodo para asignar consignatario a una orden 
    const assignConsignee = async (idx: number) => {
        if(consigneeList.length === idx) {
            setConsignee({
                companyName: '',
                fiscalAddress: '',
                itNumber: undefined,
                lastName: '',
                name: '',
                typeDocument: 'Persona jurídica',
                typeIt: 'RUC',
                userId: '',
                email: '',
                phone: undefined,
                status: true,
                createdAt: new Date(),
                updatedAt: new Date()
            });
            setIsDisabled(false);
        }  else {
            // toast.info("Procesando...");
            setLoading(true);
            let consigneeId = consigneeList[idx].id;
            // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 604 ~ assignConsignee ~ consigneeId", consigneeId)
            setOrderDetail((prev:any) => ({
                ...prev,
                consigneeId: consigneeId
            }));
            setConsignee( consigneeList[idx]);
        try {
                const response = await UpdateOrder({
                    id: orderDetail?.id,
                    consigneeId: consigneeId,
                    updatedAt: new Date()
                })
                // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 614 ~ assignConsignee ~ response", response);
                toast.success("Se asignó de forma correcta al consignatario.");
                setLoading(false);
                getOrderDetail(orderDetail.id?orderDetail.id:'');
        } catch (error) {
                // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 617 ~ assignConsignee ~ error", error)
                toast.error("hubo un error al asignar al consignatario");
                setLoading(false);
        }
        }  
    }

    // facturacion y reseña
    // handle billing
    const handleInvoiceChange = async (e: any) => {
        if(e.target.files[0] && operationDetail && orderDetail) {
            setLoading(true)
            setInvoice(e.target.files[0])
            // toast("Subiendo Archivos...");
            const responseFile =  await StoreService.UploadFile(
                e.target.files[0], 
                operationDetail.id+`/billingReview/user/`+e.target.files[0].name,
                // Estoy agregando el id del usuario para que se cree una carpeta con el id del usuario,
                // si no es esa la solucion lo puedes eliminar
                userDetail.id);
            // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 432 ~ handleInvoiceChange ~ response", responseFile)
            if(responseFile) {
                let tmpBillingReview: BillingReviewInterface = {
                    ...billingReviewData,
                    companyId: orderDetail?.companyId,
                    orderId: orderDetail.id ? orderDetail.id: '',
                    serviceId: orderDetail.serviceId,
                    userId: userDetail.id,
                    billingUrl: [...billingReviewData.billingUrl, {
                        name: e.target.files[0].name,
                        url: responseFile.toString(),
                        size: e.target.files[0].size/1024,
                    }],
                    operationId: orderDetail.operationId,
                    status: true,
                    updatedAt: new Date()
                };

                if(orderDetail?.billingReviewId && orderDetail.billingReviewId.length>0) {
                    const response = await UpdateBillingReview(billingReviewData.id ? billingReviewData.id : '', tmpBillingReview);
                    toast.success("Los archivos se actualizaron exitosamente");
                    setBillingReviewData(tmpBillingReview);
                } else {
                    //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 475 ~ handleInvoiceChange ~ tmpBillingReview", tmpBillingReview)
                    setBillingReviewData({...tmpBillingReview, createdAt: new Date()});
                    const responseBillingReview = await CreateBillingReview({...tmpBillingReview, createdAt: new Date()});
                    //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 489 ~ handleInvoiceChange ~ responseBillingReview", responseBillingReview)
                    if(responseBillingReview) {
                        setOrderDetail((prev:any) => ({
                            ...prev,
                            billingReviewId: responseBillingReview.id,
                            updatedAt: new Date(),
                        }));
                        const responseOrder = await SetOrder({...orderDetail,  billingReviewId: responseBillingReview.id, updatedAt: new Date()});
                        //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 497 ~ handleInvoiceChange ~ responseOrder", responseOrder)
                        toast.success('Los archivos se guardaron exitosamente!');
                    } else {
                        toast.error("hubo un error al almacenar la factura")
                    }
                }
                setLoading(false);
            }
        } else {
            toast.info("Por favor suba un archivo");
        }

        // setInvoice(e.target.files[0]);
    }

    const handleDeleteBilling =  async(i: number) => {
        // console.log("handleDeleteBilling", i)
        setLoading(true);
        let tmpBillingReview: BillingReviewInterface =  {
            ...billingReviewData,
            billingUrl: billingReviewData.billingUrl.filter((_, idx) => idx !== i),
            updatedAt: new Date(),
        }
        setBillingReviewData(tmpBillingReview);
        const response = await UpdateBillingReview(billingReviewData.id ? billingReviewData.id : '', tmpBillingReview);
        await StoreService.DeleteFile(billingReviewData.billingUrl[i].url,
            billingReviewData.billingUrl[i].size, userDetail.id);
        setLoading(false);
        //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 534 ~ handleDeleteBilling ~ response", response)
        toast.success("¡Se elimino con éxito el archivo!");
    }

    // handle review section
    //handle review
    const handleRatingReviewChange = (value: number) => {
        setReview((prev:any) => {
            return {
                ...prev,
                raintg: value
            }
        })
    }

    // handle review comment
    const handleValueReviewChange = (value: string) => {
        setReview((prev:any) => {
            return {
                ...prev,
                review: value
            }
        })
    }

    // handle click finalizar servicios
    const handleEndServiceClick = async () => {
        setLoading(true);
        if(review.raintg>0 && review.review.length>0 && orderDetail) {
            let tmpBillingReview: BillingReviewInterface = {
                ...billingReviewData,
                companyId: orderDetail?.companyId,
                orderId: orderDetail.id? orderDetail.id : '',
                serviceId: orderDetail.serviceId,
                userId: userDetail.id,
                operationId: orderDetail.operationId,
                reviewService:  {
                    rating: review.raintg,
                    comment: review.review,
                    userId: userDetail.id,
                },
                status: true,
                updatedAt: new Date()
            };
            if(orderDetail?.billingReviewId && orderDetail.billingReviewId.length>0) {
                setBillingReviewData(tmpBillingReview);
                const response = await UpdateBillingReview(billingReviewData.id ? billingReviewData.id : '', tmpBillingReview);
                toast.success("La reseña se actualizo exitosamente");
                // llamando a la funcion para cerrar el servicio
                FinishService({
                    serviceId: tmpBillingReview.serviceId,
                    review: {
                        rating: review.raintg,
                        comment: review.review
                    },
                    userId: userDetail.id,
                    billingReviewId: orderDetail.billingReviewId,
                    companyId: tmpBillingReview.companyId,
                    orderId: tmpBillingReview.orderId
                }).then(async (responseFunction) => {
                    const responseOrder = await SetOrder({...orderDetail, finishedUser: true, updatedAt: new Date()});
                    setOrderDetail((prev)=> ({
                        ...prev,
                        activePhase: 3,
                        finishedUser: true,
                        updatedAt: new Date()
                    }));
                    //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 592 ~ handleEndServiceClick ~ responseFunction", responseFunction);
                    toast.success("¡Ha finalizado la operación!, Gracias por usar arkabia");
                    setLoading(false);
                    GetBillingReview(billingReviewData.id ? billingReviewData.id: '');

                }).catch((error) => {
                    console.log("🚀 ~ file: CustomsAgency.tsx ~ line 594 ~ handleEndServiceClick ~ error", error)
                });
            } else  {
                setBillingReviewData({...tmpBillingReview, createdAt: new Date()});
       
                const responseBillingReview = await CreateBillingReview({...tmpBillingReview, createdAt: new Date()});
                if(responseBillingReview) {
                    const responseOrder = await SetOrder({...orderDetail,  billingReviewId: responseBillingReview.id, finishedUser: true, updatedAt: new Date()});
                    setOrderDetail((prev)=> ({
                        ...prev,
                        billingReviewId: responseBillingReview.id, 
                        finishedUser: true, 
                        updatedAt: new Date()
                    }));
                    console.log("🚀 ~ file: CustomsAgency.tsx ~ line 497 ~ handleInvoiceChange ~ responseOrder", responseOrder)
                    toast.success('la reseña se guardo exitosamente!');
                    // llamando a la funcion para cerrar el servicio
                    setOrderDetail((prev:any) => ({
                        ...prev,
                        billingReviewId: responseBillingReview.id,
                        finishedUser: true,
                        updatedAt: new Date(),
                    }));
                    FinishService({
                        serviceId: tmpBillingReview.serviceId,
                        review: {
                            rating: review.raintg,
                            comment: review.review
                        },
                        userId: userDetail.id,
                        billingReviewId: responseBillingReview.id,
                        companyId: orderDetail.companyId,
                        orderId: orderDetail.id ? orderDetail.id : ''
                    }).then((responseFunction) => {
                        
                        setLoading(false);
                        //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 622 ~ handleEndServiceClick ~ responseFunction", responseFunction);
                        toast.success("¡Ha finalizado la operación!, Gracias por usar arkabia");
                        GetBillingReview(responseBillingReview.id ? responseBillingReview.id: '');
                    }).catch((error) => {
                        setLoading(false);
                        // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 624 ~ handleEndServiceClick ~ error", error)
                    });
                   
                } else {
                    setLoading(false);
                    toast.error("hubo un error al guardar la reseña")
                }
            }
        }  else {
            setLoading(false);
            toast.warning("Selecciona un puntaje e ingresa una reseña para finalizar el servicio")
        }

    }

    /** Metodo para guardar agente de carga  */
    const handleSaveBulkingAgent = async () => {
        // toast.info("Guardando agente de carga...");
       setTimeout(() => {
            setLoading(true);
            UpdateOrder({
                id: orderDetail.id,
                bulkingAgent: bulkingAgent,
                updatedAt: new Date(),
            }).then((response) => {
                toast.success("Los datos se almacenaron con éxito");
                setLoading(false);
                getOrderDetail(orderDetail.id ? orderDetail.id : '');
            })
            .catch((error) => {
                setLoading(false);
                console.log("🚀 ~ file: CustomsAgency.tsx ~ line 895 ~ handleSaveBulkingAgent ~ error", error)
                toast.error("Hubo un problema al guardar los datos");
            })
       }, 300);
    }

    //method to get chat by id
    const getChat = (chatId: string) => {
        GetChatById(chatId, (value)=>{
            setchatDetail(value); 
        });
    }

    //obteniendo detalle de la order por requestQuote
    const getRequestQuote =async (id: string) => {
        setLoading(false);
        if(!loading) {
            try {
                setLoading(true);
                const response:any = await  GetRequestQuoteById(id);
                if(response) {
                    setRequestQuote(response);
                    setLoading(false);
                    getChat(response.chatId);
                }
                return response;
            } catch (error) {
                // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 934 ~ getRequestQuote ~ error", error)
                toast.error("Hubo un error al obtener los datos de la cotización");
            }
        }
    }

    //Obteniendo detalle de la order por cotizacion
    const getQuotationDetail = async (id: string) => {
        if(!loading) {
            setLoading(true);
            dispatch(GetQuotationById(id))
            .unwrap()
            .then((response) => {
                // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 695 ~ .then ~ response", response);
                setQuotationDetail(response);
                setLoading(false);
            })
            .catch((error) => {
                 console.log('error', error);
                setLoading(false)
            })
        }
    }


    // method to set step title acording service type
    const setPhaseTitleByServiceType = (serviceType: string) => {
        switch (serviceType) {
            case "agenciamiento-de-aduana":
                setStepTitle(phaseTypeService.agenciamiento);
                break;
            case "courier":
                setStepTitle(phaseTypeService.courier);
                break;
            case "busqueda-de-proveedor":
                setStepTitle(phaseTypeService.busquedaProveedor);
                break;
            case "compartir-contenedor":
                setStepTitle(phaseTypeService.sharedContainer);
                break;
        }
    }
    
    // method to get quotation container shared
    const getQuotationContainerShared = async (id: string) => {
        // console.log("🚀 ~ getQuotationContainerShared ~ id:", id)
        if(!loading) {
            try {
                const response = await GetQuotationContainerShared(id);    
                //console.log("🚀 ~ getQuotationContainerShared ~ response:", response);
                setQuotationContainerShared(response);
            } catch (error) {
                console.log("🚀 ~ getQuotationContainerShared ~ error:", error)
                toast.error("Hubo un error al obtener los datos de la cotización");
            }
        }
    };

    //obteniendo ordenes de firebase
    const getOrderDetail = async (id:string) => {
        if(!loading) {
            try {
                setLoading(true);
                let rq: RequestQuoteInterface | undefined = undefined;
                const responseOrder: any = await GetOrderDetail(id);

                if(responseOrder.serviceTypeId === "agenciamiento-de-aduana" || responseOrder.serviceTypeId === "courier") {
                    rq = await getRequestQuote(responseOrder.requestQuoteId);
                    getQuotationDetail(responseOrder.quotationId);
                }  else if(responseOrder.serviceTypeId === "compartir-contenedor") {
                    // get quotation container shared
                    await getQuotationContainerShared(responseOrder.quotationId);
                }
                // set step title by service type
                setPhaseTitleByServiceType(responseOrder.serviceTypeId);

                setOrderDetail(responseOrder);
                getCompany(responseOrder.companyId);

                if(!chatdetail && responseOrder.chatId.length>0) {
                    getChat(responseOrder.chatId);
                }

                getInfoFromPhase(2, responseOrder, rq);

                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    }

    //Obteniendo el id de la compañia
    const getCompany = async (companyId:string) => {
        setLoading(true);
        try {
            const response:any = await GetCompanyById(companyId);
            // console.log('respone', response);
            if(response) {
                setCompany(response);
            }
            setLoading(false)
        } catch (error) {
            console.log("error", error);
            setLoading(false)
        }
    }

    //Listando cotizaciones de la operacion
    const getOperationDetail = async (operationId: string) => {
        if(!loading) {
            setLoading(true);
            dispatch(GetOperationDetail(operationId))
            .unwrap()
            .then((response) => {
                if(response.userId === userDetail.id) {
                    setOperationDetail(response);
                    // const orderByCompany = ArrayGrouping(response.orders, "id");
                    // setOrders(orderByCompany);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("No tienes permisos para ver esta operación");
                    navigation("/"+OPERATIONS);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
        }
    }

    //obteniendo ordenes de redux
    const getOperationLocal = (operationId: string) => {
        setOperationDetail(operationList.find((o:OperationInterface) => (o.id === operationId)))
    }

    // obteniendo BillingReview de la base datos
    const getBillingReviewList = async (billingReviewId: string | undefined) => {
        if(billingReviewId && billingReviewId.length>0) {
           try {
            setLoading(true);
            const response:any = await GetBillingReview(billingReviewId);
            if(response) {
                setBillingReviewData(response);
                setReview({
                    raintg: Number(response.reviewService.rating),
                    review: response.reviewService.comment
                });
            }
            setLoading(false);
           } catch (error) {
            console.log("🚀 ~ file: CustomsAgency.tsx:1133 ~ getBillingReview ~ error", error)
            setLoading(false);
           }
        }
    }

    // obteniendo carga enviada de la base de datos
    const getCargoShippetData = async (cargoShippetId: string) => {
        // console.log("getCargoShippetData");
        if(!loading) {
             try {
                setLoading(true);
                const response:any = await GetCargoShippetId(cargoShippetId);
                // console.log("🚀 ~ file: ProcessPage.tsx ~ line 717 ~ getCargoShippetData ~ response", new Date(response.arrivalDate.toDate()))
                if(response) {
                    setCargoShippet({
                        id: response.id,
                        arrivalDate: response.arrivalDate,
                        mmsi: response.mmsi,
                        nameBoat: response.nameBoat,
                        typeBoat: response.typeBoat,
                        vTypeBoat: response.vTypeBoat,
                        operationId: response.operationId,
                        orderId: response.orderId,
                        providerId: response.providerId,
                        createdAt: response.createdAt,
                        updatedAt: response.updatedAt
                    });
                }
                setLoading(false)
            } catch (error) {
                console.log("🚀 ~ file: ProcessPage.tsx ~ line 721 ~ getCargoShippetData ~ error", error);
                setLoading(false);
            }
        }
    }

    // obteniendo documentacion
    const getDocumentation = async (documentationId:string | undefined) => {
        if(documentationId && documentationId.length>0) {
            setLoading(true);
            try {
                const response:any = await getDocumentationById(documentationId);
            if(response) {
                setDocumentationData(response);
            }
            setLoading(false);
            } catch (error) {
                setLoading(false)
            }
        }
    }

    //Obteniendo los consignatarios
    const getConsigneeList = async (userId: string, consigneeId: string) => {
        if(userId.length>0) {
            setLoading(true);
            try {
                const response = await GetAllConsigneeByUserId(userId);
                // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 974 ~ getConsigneeList ~ response", response)
                setIsDisabled(response.length > 0);
                setConsigneeList(response);
                if(response.length>0) {
                    let r = response.find(c => c.id === consigneeId);
                    if(r) {
                        setConsigneeSelected(r.typeDocument === 'Persona jurídica' ? r.companyName : r.name+' '+r.lastName);
                        setConsignee(r);
                    } else {
                        setConsigneeSelected('Seleciona un consignatario');
                    }
                } else {
                    setConsigneeSelected('Aún no tienes consignatarios guardados');
                }
                setLoading(false);
            } catch (error) {
                // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 874 ~ getConsigneeList ~ error", error)
                setLoading(false);
                toast.error('hubo un error al obtener la lista de consignatatios :c')
            }
        }
    }

    //Obteniendo las facturas
    const getInvoiceTranslation = async (id: string, incoterm: string, coin: string) => {
        let products: ProductDeclareInterface[] = [];
        if(id && id.length>0) {
            setLoading(true);
            try {
                const response:any = await GetInvoiceTranslationById(id);
                // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 913 ~ getInvoiceTranslationList ~ response", response)
                if(response) {
                    if(response.provider.length===0) {
                        setInvoiceTranslation({
                            ...response,
                            provider: [{
                                city: '',
                                country: {
                                    name: ''
                                },
                                countryAcquisition: {
                                    name: requestQuote.transport.origin?.country || '',
                                    alpha2Code: requestQuote.transport.origin?.alpha2Code || requestQuote.transport.origin?.country,
                                    imgFlag: requestQuote.transport.origin?.imgFlag || COUNTRY_FLAG_BASE+requestQuote.transport.origin?.country.toLocaleLowerCase()+".svg",
                                },
                                currency: coin,
                                financialEntity: '',
                                incoterm: incoterm,
                                invoiceNumber: '',
                                name: '',
                                paymentType: '',
                                phone: '',
                                placeDelivery: requestQuote.transport.finalDelivery || '',
                                product: products,
                                providerAddress: '',
                                providerType: ''
                            }]
                        });
                    } else {
                        setInvoiceTranslation(response);
                    }
                }
                setLoading(false)
            } catch (error) {
                // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 925 ~ getInvoiceTranslationList ~ error", error)
                setLoading(false);
                toast.error('hubo un error al obtener la lista de factura traducida :c')
            }
        } else {
            setLoading(true);
            // let products: ProductDeclareInterface[] = [];
            await getConsigneeList(orderDetail.user.id, orderDetail.consigneeId ? orderDetail.consigneeId: '');
            const consigneeSelect = consigneeList.find(c => c.id === orderDetail.consigneeId);            
            
            setInvoiceTranslation((prev:any) => ({
               ...prev,
               importer: {
                ...consigneeSelect ? {
                    commercialLevel: '',
                    itNumber: consigneeSelect.itNumber ? consigneeSelect.itNumber: undefined,
                    lastName: consigneeSelect.lastName,
                    name: consigneeSelect.name,
                    nameCompany: consigneeSelect.companyName,
                    typeDocument: consigneeSelect.typeDocument,
                    typeIt: consigneeSelect.typeIt
                } : prev.importer
               },
                provider: [{
                    name: '',
                    invoiceNumber: '',
                    country: {
                        name: '',
                        alpha2Code: '',
                        imgFlag: '',
                    },
                    city: '',
                    providerType: '',
                    providerAddress: '',
                    phone: '',
                    incoterm: incoterm,
                    currency: coin,
                    countryAcquisition: {
                        name: requestQuote.transport.origin?.country || '',
                        alpha2Code: requestQuote.transport.origin?.alpha2Code || requestQuote.transport.origin?.country,
                        imgFlag: requestQuote.transport.origin?.imgFlag || COUNTRY_FLAG_BASE+requestQuote.transport.origin?.country.toLocaleLowerCase()+".svg",
                    },
                    paymentType: '',
                    financialEntity: '',
                    placeDelivery: requestQuote.transport.finalDelivery || '',
                    product: products,
                }]
            }));
            setLoading(false);
            // console.log("products", products);
        }
    }

    const handleConfirmInvoiceTranslationClick = async (background: boolean = false) => {
        if(invoiceTranslation.id && invoiceTranslation.id.length>0 && orderDetail.invoiceTranslationId && orderDetail.invoiceTranslationId?.length > 0) {
     
            !background && setLoading(true);
            !background && toast.info("Actualizando datos..",)
            const response = await UpdateInvoiceTranslation(invoiceTranslation);
            // console.log("🚀 ~ file: CustomsAgency.tsx:1380 ~ handleConfirmInvoiceTranslationClick ~ response", response)
            !background && (response ? toast.success("Los datos se actualizaron éxitosamente") : toast.error("Hubo un error al actualizar los datos"));

            // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 1073 ~ handleConfirmInvoiceTranslationClick ~ response", response)
            !background &&  setLoading(false)
        } else {
            try {
                !background && setLoading(true)
                const response = await CreateInvoiceTranslation(invoiceTranslation);
                // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 1105 ~ handleConfirmInvoiceTranslationClick ~ response", response)
                setInvoiceTranslation((prev) => ({
                    ...prev,
                    id: response?.id,
                }));

                console.log(!isDisabled)

                setOrderDetail((prev) => ({
                    ...prev,
                    invoiceTranslationId: response?.id
                }));
                await UpdateOrder({...orderDetail, invoiceTranslationId: response?.id, updatedAt: new Date()});
                !background && setLoading(false);
                !background && toast.success("Los datos se guardaron con éxito");
            } catch (error) {
                console.log("🚀 ~ file: CustomsAgency.tsx ~ line 1111 ~ handleConfirmInvoiceTranslationClick ~ error", error)
                toast.error("Hubo un error al almacenar los datos");
                setLoading(false);
            }
        }
    }

    const handleInvoicestatementSave = async (providerIdx: number, q1: boolean, q2: boolean, q3: boolean, q4: boolean, q5: boolean, q6: boolean, q7: boolean, 
        q8: boolean) => {
            // console.log(q1, q2, q3, q4, q5, q6, q7, q8);
            let providetTmp = invoiceTranslation.provider.map((p, idx) => idx === providerIdx ? {...p, statement: {
                q1: q1,  q2: q2,  q3: q3,  q4: q4,  q5: q5,  q6: q6,  q7: q7,  q8: q8,
            }} : p )
            
            setInvoiceTranslation((prev) => ({
                ...prev,
                provider: providetTmp,
                updatedAt: new Date()
            }));
            
        if(invoiceTranslation.id) {
            setLoading(true)
           try {
            const response = await UpdateInvoiceTranslation({...invoiceTranslation, provider: providetTmp, updatedAt: new Date()});
            //console.log("🚀 ~ file: CustomsAgency.tsx:1390 ~ response", response)
            setLoading(false);
            toast.success("¡Los datos se guardaron correctamente!")
           } catch (error) {
                setLoading(false);
                //console.log("🚀 ~ file: CustomsAgency.tsx:1391 ~ error", error)
                toast.error("Hubo un error al guardar la declaración");
           }
        } else {
            toast.warning("Guarda la factura para continuar...");
        }
    }
    
    // handle seach text change
    const handleSeachChange = useCallback(
        debounce((text)=> {
          setSearchText(text);
        }, 500), [searchText]);


    //Obteniendo Arribo y canal de control 
    const getArrivalControlChannel = async (id: string) => {
        if(id.length>0) {
            setLoading(true)
            try {
                const response:any = await GetArrivalControlChannel(id);
                // console.log("🚀 ~ file: ProcessPage.tsx ~ line 1104 ~ getArrivalControlChannel ~ response", response)
                setArrivalControlChannel(response);
                setLoading(false)
            } catch (error) {
                console.log("🚀 ~ file: ProcessPage.tsx ~ line 1108 ~ getArrivalControlChannel ~ error", error)
                setLoading(false);
                toast.error('hubo un error al obtener los datos de arribo y control')
            }
        }
    }

     /** Subiendo archivos de aviso de llegada */
    const handleNoticeArrivalChange = async (d:any) => {
        if(d .target.files[0] && operationDetail && orderDetail) {
            // toast("Subiendo Archivos...");
            try {
                setLoading(true)
                const responseFile = await StoreService.UploadFile(
                    d.target.files[0], 
                    operationDetail.id+`/noticeArrival/`+d.target.files[0].name,
                    // Estoy agregando el id del usuario para que se cree una carpeta con el id del usuario,
                    // si no es esa la solucion lo puedes eliminar
                    userDetail.id
                );
                // console.log("🚀 ~ file: ProcessPage.tsx ~ line 577 ~ handleDocumentationChange ~ responseFile", responseFile)
                
                if(responseFile) {
                    let tmpArrivalControlChannel: ArrivalControlChannel = {
                       ...arrivalControlChannel,
                       noticeArrival: [
                        ...arrivalControlChannel.noticeArrival, {
                            name: d.target.files[0].name,
                            url: responseFile.toString(),
                            size: d.target.files[0].size/1024,
                            userId: orderDetail.user.id,
                        }
                       ]
                    }

                    setArrivalControlChannel(tmpArrivalControlChannel);
                    
                    if(orderDetail?.arrivalControlChannelId && orderDetail.arrivalControlChannelId.length>0) {
                        await UpdateArrivalControlChannel(tmpArrivalControlChannel);
                    } else {
                        await handleSaveArrivalControlChannel();
                    }
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                //console.log("🚀 ~ file: FilesFLC.tsx ~ line 120 ~ handleComercialInvoiceChange ~ error", error)
                toast.error("Hubo un error al subir el archivo a Facturacion comercial");
            }
        } else {
            toast.info("Por favor suba un archivo");
        }
    }

    /** Eliminando archivos de factura comercial */
    const handleDeleteNoticeArrival = async (dd: number) => {
        // console.log("🚀 ~ file: ProcessPage.tsx:1490 ~ handleDeleteNoticeArrival ~ dd", dd)
        try {
            // setLoading(true);
            let tmpArrivalControlChannel: ArrivalControlChannel = {
                ...arrivalControlChannel,
                noticeArrival: [...arrivalControlChannel.noticeArrival.filter((_, idx) => idx !== dd)],
                updatedAt: new Date(),
            }
            // console.log("🚀 ~ file: ProcessPage.tsx:1495 ~ handleDeleteNoticeArrival ~ tmpArrivalControlChannel", tmpArrivalControlChannel)
            setArrivalControlChannel(tmpArrivalControlChannel);
            const response = await UpdateArrivalControlChannel(tmpArrivalControlChannel);
            await StoreService.DeleteFile(arrivalControlChannel.noticeArrival[dd].url, 
                arrivalControlChannel.noticeArrival[dd].size, userDetail.id);
            setLoading(false);
            // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 534 ~ handleDeleteDocument ~ response", response)
            toast.success("¡Se elimino con éxito el archivo!");
        } catch (error) {
            // console.log("🚀 ~ file: FilesFLC.tsx ~ line 388 ~ handleDeleteComercialInvoice ~ error", error)
            setLoading(false);
            toast.error("Hubo un error al eliminar la Factura comercial");
        }
    }

    const handleClickCancelSix = () => {
        setShowEdit(false)
    }

    const handleEditSix = () => {
        setShowEdit(true)
    };

       // method to send whatsapp notification to provider
    const sendProviderWspNotification = async () => {
        try {
            // find all user to send whatsapp notification
            const users = chatdetail ? chatdetail.members.filter((m) => (m.userId !== userDetail.id && !m.isCompany)) : [];
            // iterate users to send whatsapp notification
            users.length> 0 && users.forEach(async (user) => {
                // get user detail by id
                const respUser: any = await GetUserById(user.userId);
                // send whatsapp notification
                sendUserMessageNotification(respUser.phoneCode+""+respUser.phone, respUser.name + " " + respUser.lastName, chatdetail?.companyName ? chatdetail.companyName : "")
                .then((wsp) => {
                    // console.log("🚀 ~ file: ChatPage.tsx:490 ~ .then ~ wsp:", wsp);
                }).catch((errorWsp) => {
                    console.log("🚀 ~ file: ChatPage.tsx:492 ~ .then ~ error:", errorWsp);
                });      
            });
            
        } catch (error) {
            console.log("🚀 ~ file: ChatPage.tsx:488 ~ sendProviderWspNotification ~ error:", error);
        }
    }
    
    // method to send whatsapp notification to user
    const sendUserWspNotification = async () => {
        try {
            // find all user to send whatsapp notification
            const users = chatdetail ? chatdetail.members.filter((m) => (m.userId !== userDetail.id && m.isCompany)) : [];
            // iterate users to send whatsapp notification
            users.length> 0 && users.forEach(async (user) => {
                // get user detail by id
                const respUser: any = await GetUserById(user.userId);
                sendProviderMessageNotification(respUser.phoneCode+""+respUser.phone, userDetail.name + " " + userDetail.lastName, chatdetail?.companyName ? chatdetail.companyName : '')
                .then((wsp) => {
                    // console.log("🚀 ~ file: ChatPage.tsx:505 ~ .then ~ wsp:", wsp);
                }).catch((errorWsp) => {
                    console.log("🚀 ~ file: ChatPage.tsx: 507 ~ .then ~ error:", errorWsp);
                });
            });
        } catch (error) {
            console.log("🚀 ~ file: ChatPage.tsx:510 ~ sendUserWspNotification ~ error:", error)
        }
    }
    
    const sendWhatsappNotification =() => {
        try {
            userDetail.userType.includes('user') ? sendUserWspNotification() : sendProviderWspNotification();
        } catch (error) {
            console.log("🚀 ~ file: ChatPage.tsx:491 ~ debounce ~ error:", error);
        }
    }

    const handleClickBefore = () => {
        if(phase > 0 && window.innerWidth <= 700) {
            setPhase(0);
        } else {
            navigation('/'+OPERATIONS);
        }
    }

    // use effect to navigate to top
    useEffect(() => {
        setTimeout(() => {
            navigateToSection(ref);
        }, 150);
    }, [])


    // use effect to get operation id from params
    useEffect(()=>{
        if(params && params.operationId) {
            if(operationList && operationList.length>0) {
                getOperationLocal(params.operationId);

            } else {
                getOperationDetail(params.operationId);
            }
        }
    }, [params.operationId]);

    // use effect to get order id detail from params
    useEffect(() => {
        if(params && params.orderId) {
            getOrderDetail(params.orderId);
        }
    }, [params.orderId])

    // use effect to get num phase from params
    useEffect(()=> {
        const paramsPhase = params.numPhase ? parseInt(params.numPhase) : 0;
        setPhase(paramsPhase);
    }, [params.orderStep]); 

   return (
    <div className="customsAgency-container">
        <OperationHeader
            operationDetail={operationDetail}
            teams={operationDetail ? operationDetail.orders.map(or => 
                    ({operationId: operationDetail.id ?operationDetail.id: '', 
                        orderId: or.id ,teamName: or.companyName})):  
                        []
                    }
            onClickHome={()=>{}}
            updateOperation={()=>getOperationDetail(operationDetail?.id || "")}
            showDots = {userDetail.userType.includes('user') && !requestQuote.quoteGenerated}
            onClickBefore={handleClickBefore}
        />
        <div className="customsAgency-content" ref={ref}> 
            <div className="customsAgency-content-row">
                <div className="customsAgency-left">
                    <div className="customsAgency-left-header">
                        <div className="customsAgency-title paragraph-header">Órdenes</div>
                        <ButtonBadget
                            name={
                                <MessageBlack/>
                            }
                            handleBadget={()=>handleMessageClick(chatdetail?.id?chatdetail.id:'')}
                            badget={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
                        />
                    </div>
                    <ImportPhasesCard
                        type="Orden"
                        serviceTypeLabel={orderDetail?.serviceTypeLabel ? orderDetail.serviceTypeLabel : 'Importación'}
                        typeOperation={orderDetail ? orderDetail.type : ''}
                        companyName={orderDetail? orderDetail.companyName : ''}
                        order={{
                            num: Number(orderDetail.number),
                            numComments: requestQuote.numServiceComments,   
                            date: FormatDate(orderDetail.createdAt ? new Date(orderDetail.createdAt.toDate()) : new Date() )
                        }}
                        rating={requestQuote.numServiceRating}
                        activePhase={phase}
                        online={true}
                        black={true}
                        // order={orderMin}
                        country={{
                            alpha2Code: requestQuote.country?.alpha2Code ? requestQuote.country.alpha2Code : '',
                            city: requestQuote.country?.city ? requestQuote.country.city : 'Lima',
                            imgFlag: requestQuote.country?.imgFlag ? requestQuote.country.imgFlag : '',
                        }}
                        phases={stepTitle}
                        currency={quotationDetail?.currency || 'USD'}
                        price={orderDetail ? orderDetail.price : 0.00}
                        showFlags={true}
                        handlePhaseClick={handlePhaseClick}
                        resume={false}
                        handleClick={()=>{}}
                        unlockedPhases={orderDetail?.unlockedPhases}/>
                    {chatdetail?.attachments && chatdetail?.attachments.length>0 &&
                        <div className="customsAgency-left-multimedia">
                            <Multimedia 
                                multimedia={chatdetail?.attachments}
                                title=""
                                number={5}
                            />
                        </div>
                    }
                </div>
                    
                {/* { (window.innerWidth > 699 ? !showLateral : showLateral) && */}
                <div className="customsAgency-right">
                    {phase !== 0 && 
                    // !showchat && 
                        <div className="customsAgency-chat-content">
                            <HeaderCustomsPhases
                                numPhase={phase}
                                title={stepTitle[phase-1]}
                                handleMessageclick={()=>handleMessageClick(chatdetail?.id?chatdetail.id:'')}
                            />
                            <ButtonBadget
                                name={window.innerWidth <881 ? <MessageBlack/> : "Ver mensajes"}
                                handleBadget={()=>handleMessageClick(chatdetail?.id?chatdetail.id:'')}
                                badget={chatdetail ? chatdetail.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread : 0}
                            />
                        </div>
                    }

                    {showchat && 
                    phase === 0 ? // seccion chat
                    <div className={showchat ? "customsAgency-chat--content" : "customsAgency-chat--content-chat"} style={{display: mobileMode ? (showMenu ? 'none' : '') : ''}}>  {/** chat seccion */}
                        <MessageTopbar
                            searchText=""
                            setSearchText={(e)=> handleSeachChange(e)}
                            team={chatdetail && chatdetail.members ? chatdetail.members.filter(m => m.userId !== userDetail.id && m.isCompany) : []}
                            typeChat={window.innerWidth <= 880 ? "Órdenes" : ''}
                            onClickType={() => {}}
                            canAddMembers
                        />
                        { chatdetail && 
                            <Chat 
                                chatId={chatdetail?.id ? chatdetail.id : "" } 
                                userId={userDetail?.id?userDetail.id:""}
                                userName={userDetail?.name + " " + userDetail?.lastName} 
                                searchText={searchText}
                                sendWhatsappNotification={sendWhatsappNotification}
                                paramHeight={window.innerHeight}
                            />
                        }
                    </div> : 
                    <> 
                        {/**Seccion detalle de la cotizacion */}
                       {phase !== -1  && <PhaseCustomsAgency
                            parentRef={parentRef}
                            title={stepTitle[phase-1]}
                            phase={phase}
                            setPhase={setPhase}
                            orderStatus={orderStatus}
                            setOrderStatus={setOrderStatus}
                            quotationDetail={quotationDetail}
                            orderDetail={orderDetail}
                            setOrderDetail={setOrderDetail}
                            requestQuote={requestQuote}
                            setRequestQuote={setRequestQuote}
                            consigneeSelected={consigneeSelected}
                            consigneeList={consigneeList}
                            consignee={consignee}
                            setConsignee={setConsignee}
                            consigneeError={consigneeError}
                            handleSaveConsigneeClick={handleSaveConsigneeClick}
                            assignConsignee={assignConsignee}
                            isDisabled={isDisabled}
                            setIsDisabled={setIsDisabled}
                            handleSaveBulkingAgent={handleSaveBulkingAgent}
                            bulkingAgent={bulkingAgent}
                            setBuilkingAgent={setBuilkingAgent}
                            operationDetail={operationDetail}
                            documentationData={documentationData}
                            setDocumentationData={setDocumentationData}
                            invoice={invoice}
                            billingReviewData={billingReviewData}
                            setBillingReviewData={setBillingReviewData}
                            handleInvoiceChange={handleInvoiceChange}
                            handleDeleteBilling={handleDeleteBilling}
                            review={review}
                            setReview={setReview}
                            handleRatingReviewChange={handleRatingReviewChange}
                            handleValueReviewChange={handleValueReviewChange}
                            handleEndServiceClick={handleEndServiceClick}
                            cargoShippet={cargoShippet}
                            setCargoShippet={setCargoShippet}
                            invoiceTranslation={invoiceTranslation}
                            setInvoiceTranslation={setInvoiceTranslation}
                            commercialLevel={CommercialLevel}
                            handleConfirmInvoiceTranslationClick={handleConfirmInvoiceTranslationClick}
                            getInvoiceTranslation={getInvoiceTranslation}
                            handleInvoicestatementSave={handleInvoicestatementSave}
                            arrivalControlChannel={arrivalControlChannel}
                            setArrivalControlChannel={setArrivalControlChannel}
                            handleNoticeArrivalChange={handleNoticeArrivalChange}
                            handleDeleteNoticeArrival={handleDeleteNoticeArrival}
                            handleSaveArrivalControlChannel={handleSaveArrivalControlChannel}
                            showEdit={showEdit}
                            setShowEdit={setShowEdit}
                            ChannelControls={ChannelControls}
                            handleEditSix={handleEditSix}
                            handleClickCancelSix={handleClickCancelSix}
                            loading={loading}
                            setLoading={setLoading}
                            unlockedPhases={orderDetail.unlockedPhases || 0}

                            updateRqeustQuote={()=>getRequestQuote(orderDetail.requestQuoteId)}
                            quotationContainerShared={quotationContainerShared}
                        />}

                    </>
                    }
                </div>
                {/* } */}
            </div>
        </div>
    </div>

    )
}