import React from 'react';
import './TableResponsive.css';

interface TableResponsiveProps {
    columns: string[];
    data: { [key: string]: any }[];
    footer?: React.ReactNode;
    handleClick: (params1: string, params2: string) => void;
    operationStatus: "order" | "quote";
}

export const TableResponsive: React.FC<TableResponsiveProps> = ({ 
    columns, 
    data, 
    footer,
    handleClick,
    operationStatus
}) => {

    return (
        <div className="responsive-table">
            <div className="responsive-table-header">
                {columns.map((column, index) => (
                    <div 
                        key={index} 
                        className={`smalltext responsive-table-cell ${
                            columns[index] === "Importe total" ? "service-column" : ""
                        }`}>
                        {column}
                    </div>
                ))}
            </div>
            <div className="responsive-table-body">
                {data.length > 0 && data.map((row, rowIndex) => (
                    <div key={rowIndex} className="responsive-table-row" onClick={()=>handleClick(row.id, operationStatus === "order" ? "1" : row.quotationId ) } >
                        {columns.map((column, colIndex) => (
                            <div 
                                key={colIndex} 
                                className={`smalltext responsive-table-cell ${
                                    columns[colIndex] === "Importe total" ? "service-column" : ""
                                }`}>
                                {row[column]}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {footer && <div className='responsive-redirect'>
                {footer}
            </div>}
        </div>
    );
};
