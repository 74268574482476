import React, { useEffect, useState } from "react";

/** importando estilos */
import './ModalLateralContainer.css';
import { ModalLateral } from "../ModalLateral/ModalLateral";
import { Button } from "../Button/Button";
import { QuotationContainerSharedInterface } from "../../interfaces/QuotationContainerSharedInterface";
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";
import { FormatDate, FormatDateMinString } from "../../utils/formatDate";
import { VerifyBlack } from "../../icons/solid/VerifyBlack";
import { VerifyBlue } from "../../icons/solid/Verify_blue";
import { Rating } from "../Rating/Rating";
import { CityCountryDisplay } from "../CityCountryDisplay/CityCountryDisplay";
import { ServiceInterface } from "../../interfaces/Service";
import { containerSharedInterface } from "../../interfaces/containerSharedInterface";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";

interface ModalLateralContainerProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    quotationContainerShared: QuotationContainerSharedInterface;
    containerSelected: containerSharedInterface;
    handleReserveClick: () => void;
    service: ServiceInterface; 
}

export const ModalLateralContainer: React.FC<ModalLateralContainerProps> = ({
    show,
    setShow,
    quotationContainerShared,
    containerSelected,
    handleReserveClick,
    service
}) => { 

    const verify = true;
    const numberVerify = 2;

    return (
        <ModalLateral
            show={show}
            setShow={setShow}
            title="Detalles">
            <div className="modalLateralcontainer-body">
                <div className="modalLateralcontainer-first">
                    <div className="modalLateralcontainer-firt-row">
                        <div className="modalLateralcontainer-firt-row-header">
                            <div className='modalLateralcontainer-firt--img'>
                                <img 
                                    className='modalLateralcontainer-firt-img'
                                    src={containerSelected.containerImage}/>
                            </div>
                            <div className='paragraph-header cardContainer-fact-service-title'>
                                {containerSelected.containerName}
                            </div>
                            {/* <div className="cardContainer-fact-service-check">
                                {verify && numberVerify <= 2 ? 
                                <VerifyBlack onClick={()=>{}}/> : 
                                <VerifyBlue onClick={()=>{}}/>}
                            </div> */}
                        </div>
                        <div className="modalLateralcontainer-firt-row-header-detail">
                            <Rating rating={service ? service?.rating : 4.5} /> 
                            <CityCountryDisplay
                                city={""}
                                country={service ? service?.country.alpha2Code : ''}
                                flagImg={service ? service.country.imgFlag : ''}
                            />
                        </div>
                    </div>
                    <div className="modalLateralcontainer-firt-column">
                    <div className="smalltext-header">Dirección de recibo de mercadería</div>
                    <div className="smalltext">{containerSelected.originAddress}</div>
                    </div>
                    <div className="modalLateralcontainer-firt-column">
                    <div className="smalltext-header">Origen</div>
                    <div className="smalltext">{quotationContainerShared.origin}</div>
                    </div>
                    <div className="modalLateralcontainer-firt-column">
                    <div className="smalltext-header">Destino</div>
                    <div className="smalltext">{quotationContainerShared.destination}</div>
                    </div>
                    <div className="modalLateralcontainer-firt-column">
                    <div className="smalltext-header">Recibe hasta</div>
                    <div className="smalltext">
                        {FormatDateMinString(containerSelected.receiveDate.toDate())}  
                        <span className="smalltext-header">{` (Faltan ${formatDistanceToNow(containerSelected.receiveDate.toDate(), {locale: es})})`}</span>
                    </div>
                    </div>
                    <div className="modalLateralcontainer-firt-column">
                    <div className="smalltext-header">Entrega en</div>
                    <div className="smalltext">{quotationContainerShared.deliveryAddress}</div>
                    </div>
                    <div className="modalLateralcontainer-firt-column">
                    <div className="smalltext-header">Llegada estimada</div>
                    <div className="smalltext">{FormatDateMinString(quotationContainerShared.arrivalDate)}</div>
                    </div>
                </div>

                <div className="modalLateralcontainer-first">
                    <div className="modalLateralcontainer-firt-row modalLateralcontainer-firt-title">
                        <div className="smalltext-header">ESCALA DE PRECIOS</div>
                        <div className="smalltext-header">USD por m³</div>
                    </div>
                    <div className="modalLateralcontainer-firt-row">
                        <div className="smalltext">De 0 a 1 m³</div>
                        <div className="smalltext">{ConverMoneyFormat(containerSelected.rateUpTo1CubicMeter.toString())}</div>
                    </div>
                    <div className="modalLateralcontainer-firt-row">
                        <div className="smalltext">Mayor a 1 hasta 5 m³</div>
                        <div className="smalltext">{ConverMoneyFormat(containerSelected.rateFor1To5CubicMeters.toString())}</div>
                    </div>
                    <div className="modalLateralcontainer-firt-row">
                        <div className="smalltext">Mayor 5 m³</div>
                        <div className="smalltext">{ConverMoneyFormat(containerSelected.rateAbove5CubicMeters.toString())}</div>
                    </div>
                </div>

                <div className="modalLateralcontainer-first">
                    <div className="modalLateralcontainer-firt-row modalLateralcontainer-firt-title">
                        <div className="smalltext-header">SERVICIO DE TRANSPORTE ALL-IN</div>
                    </div>
                    {/* <div className="modalLateralcontainer-firt-row">
                        <div className="smalltext">{Number(quotationContainerShared.cargoWeight).toFixed(1)} kg ; {Number(quotationContainerShared.cargoVolume).toFixed(1)} m³</div>
                        <div className="smalltext">{Number(quotationContainerShared.cargoVolume).toFixed(1)} m³ se redondea como {Number(quotationContainerShared.estimatedVolume).toFixed(1)} m³</div>
                    </div> */}
                    <div className="modalLateralcontainer-firt-row">
                        <div className="smalltext">
                            Total: {Number(quotationContainerShared.cargoWeight).toFixed(1)} kg ; {Number(quotationContainerShared.cargoVolume).toFixed(1)} m³
                        </div>
                        {Number(quotationContainerShared.cargoVolume) % 1 !== 0 && (
                            // Si tiene decimales
                            <div className="smalltext">
                                {Number(quotationContainerShared.cargoVolume).toFixed(1)+"m³ se redondea a "+
                                quotationContainerShared.estimatedVolume} m³
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="modalLateralcontainer-bottom">
                <div className="modalLateralcontainer-bottom_column">
                    {/* <div className="modalLateralcontainer-firt-row modalLateralcontainer-firt-row_">
                        <div className="smalltext">Total: 450 kg ; 0.7 m3</div>
                        <div className="smalltext">0.7 m3 es igual a 1 m3</div>
                    </div> */}
                    <div className="modalLateralcontainer-firt-row modalLateralcontainer-firt-row_">
                        <div className="small-subheader">Total a pagar:</div>
                        <div className="modalLateralcontainer-firt-row modalLateralcontainer-firt-row_">
                            <div className="medium-header">{quotationContainerShared.price}</div>
                            <div className="smalltext">USD</div>
                        </div>
                    </div>
                    <div style={{color: "#EB5757"}} className="smalltext">
                        *Incluye transporte y desaduanaje, no incluye los impuestos de la mercancia.
                    </div>
                </div>
                <Button 
                    color="blue-2"
                    content="Reservar"
                    onClick={()=>handleReserveClick()}
                />
            </div>
        </ModalLateral>
    )
}
