import React, { useCallback, useEffect, useState } from "react";

import debounce from 'lodash.debounce';

import { IShipment } from "../../interfaces/Shipment";
import { TextField } from "../TextField/TextField";
import "./Shipment.css";
import { ShipmentPortsList } from "./ShipmentPortsList";

// importando servicios
import {GetPortByLabel} from '../../Services/Port.service';
import { GetAirportLabel } from "../../Services/Airport.service";
import userEvent from "@testing-library/user-event";
import { PortInterface, PortsInterface } from "../../interfaces/PortInterface";
import { AirportInterface, AirportsInterface } from "../../interfaces/AirportInterface";
import { set } from "date-fns";
import { ErrorInterface } from "../../interfaces/ErrorInterface";

interface ShipmentProps {
  text: string;
  placeholder?: string;
  error? : ErrorInterface
  role:string
  type: string | undefined;
  shipment: IShipment | undefined;
  setShipment: (e: IShipment) => void;
  disabled: boolean;
  clearShipment: boolean;
  isDestination?: boolean;
  setClearShipment: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
  handleFocus?: () => void;
  handleBlur?: () => void;
  showOnlyPort?: boolean;
}

export const Shipment: React.FC<ShipmentProps> = ({ 
  text,
  placeholder = " ",
  error = {
    message: "",
    status: false
  },
  shipment,
  type,
  setShipment,
  role,
  disabled,
  isDestination = false,
  clearShipment = false,
  setClearShipment = () => {},
  setSearchActive = () => {},
  handleFocus = () => {},
  handleBlur = () => {},
  showOnlyPort = false
 }) => {

  const [portSelected, setPortSelected] = useState<string>('');

  // use state to manage the phase of search port
  const [phase, setPhase] = useState<"city" | "port">("port");
  // list of ports by city
  const [portList, setPortList] = useState<PortsInterface>({});
  //list of ports to city selected
  const [citySelected, setCitySelected] = useState<PortInterface[]>([]);
  // list of airports
  const [airports, setAirports] = useState<AirportsInterface>({});
  // airport selected
  const [airportSelected, setAirportSelected] = useState<any[]>([]);

  const [textFieldName, setTextFieldName] = useState(text);
  const [loading, setLoading] = useState<boolean>(false);

  const setOriginPort = (port: any) => {
    if(port) {
      setPortList({});
      setPortSelected(`${port.type === "city" ? (port.city+", "+port.country) : (port.name+" - "+port.portIsoCode)}`);
      setShipment({
        ...shipment,
        ...port
      })
    }
  };

  // method to set airport selected
  const setAirPort = (airport: any) => {
   
    if(airport) {
      setAirports({});
      setPortSelected(`${airport.type === "city" ? airport.city+", "+airport.country : airport.airport}`);
      setShipment({
        ...shipment,
        country: airport.country,
        city: airport.city,
        airport: airport.airport || "",
        alpha2Code: airport.alpha2Code || "",
        type: airport.type
      })
    }
   
  }

  // method to seach ports and group by country
  const searchPort = async (value: string) => {
    if(type !== 'sea') return;

    setLoading(true)
    try {     
        const response: any = await GetPortByLabel(value);
        let portList = [...response.ports];

        if(response && response.ports.length > 0){
          // if value contains Peru, Lima add port chancay
          if("peru".includes(value.toLowerCase())) {
            portList.push({
              city: "Chancay",
              country: "PE",
              name: "Chancay",
              portIsoCode: "PECHY",
              type: "PORT"
            });
          }

          // if value constains Lima delete lima port from list
          if("lima".includes(value.toLowerCase())) {
            portList = portList.filter((port: any) => port.name !== "LIMASSOL" && port.city !== "Lima");
          }
          setPortList(groupShipmentByCountry(portList));
                  
        } else {
          setPortList({});
        }
    } catch (error) {
      console.log(error);
      clearList();
    }
    setLoading(false);
  }

  // method to seach airports and group by country
  const searchAirport = async (value: string) => {
    if(type !== 'air') return;

    try {
      const response = await GetAirportLabel(value);      
      if(response && response.length > 0){
        setAirports(groupShipmentByCountry(response));
      } else {  
        setAirports({});
      }
    } catch (error) {
      console.log(error);
      clearList();
    }
    setLoading(false);
  }

  const handleChangeInput = useCallback(
    debounce((e)=>{
      let value = e.target.value;
      setLoading(true);
      setPhase("city");
      if(value.length>=2) {
        setSearchActive(true);
        type === 'sea' ? searchPort(value) : ( type === "air" && searchAirport(value));
      } else {
        clearList();
        setSearchActive(false);
      }
      setLoading(false);
    }, 500),
  [setPortSelected, type]);

  // method to clear list
  const clearList = () => {
    setPortList({});
    setAirports({});
    setCitySelected([]);
    setAirportSelected([]); 
    setSearchActive(false);
  }

  // method to group by city
  const groupShipmentByCountry = (data: any[]) => {
    // this gives an object with dates as keys
    const groupsData =  data.reduce((groups:any, item) => {
        var city = type === 'sea' ? item.city : item.cityname;
        var group = groups[city] || (groups[city] = []);
        group.push(item);
        return groups;
    }, { });
    return groupsData;
  }

  const getScrollStatus = (): string => {
    if(phase === 'city') {
      return Object.keys(portList).length<=2 && citySelected.length<=2 ? "ports-content-no-scroll" : ''
    } else {
      return citySelected.length<=2 ? "ports-content-no-scroll" : ''
    }
  }

  useEffect(()=> {
    if(shipment && shipment.type) {
      // setSearchActive(true);

      setPortSelected(type === 'sea' ?  shipment.type === "PORT" ? (`${shipment.name} - ${shipment.portIsoCode}`) : shipment.city+", "+shipment.country :
        (type === 'air' ? (shipment.type === "AIRPORT" ? shipment.airport || "" : shipment.city+", "+shipment.country ) : ""));
    } else {
      setPortSelected('');
      // setSearchActive(false);
    }
  }, [])

  useEffect(() => {
    if(type && type.length>0) {
      clearList();
    }
  }, [type]);

  useEffect(() => {
    if(clearShipment) {
      setPortSelected('');
      clearList();
      setClearShipment(false);
    }
  }, [clearShipment]);

  useEffect(() => {
    setTextFieldName(text);
  }, [text]);

  // console.log("portList", portList, "citySelected", citySelected, showOnlyPort);

  return (
    <div className="shipment" role={role}>
      <TextField
        title={textFieldName}
        placeholder={placeholder}
        value={portSelected}
        onChange={(e)=>{setPortSelected(e.target.value); handleChangeInput(e);}} 
        autoComplete="off"
        disable={disabled}
        role='text-shipment'
        onFocus={handleFocus}
        onBlur={handleBlur}/>
        {error.status && <div className="text-error tinytext-header">{error.message}</div>}
        {(Object.keys(portList).length> 0 ||  Object.keys(airports).length> 0) && !(type === "air" && Object.keys(portList).length> 0) && 
          !(type === "port" && Object.keys(airports).length> 0) &&
        
          <div role='shipmentPortsListRole' className={`ports-content ${getScrollStatus()}`}>
            {phase === 'city' ? Object.keys(Object.keys(portList).length> 0 ? portList : (Object.keys(airports).length> 0 ? airports : [])).map((city: any) => (
              <ShipmentPortsList
                  key={city}
                  ports={Object.keys(portList).length>0 ? portList[city] : []}
                  airports={Object.keys(airports).length>0 ? airports[city] : []}
                  setOriginPort={(e:any)=> type ==='sea' ? setOriginPort(e) : setAirPort(e)}
                  setTextFieldName={setTextFieldName}
                  role='shipmentPortsList'
                  typeSelected={type}
                  textFieldName={textFieldName}
                  phase={phase}
                  setPhase={setPhase}
                  setCitySelected={setCitySelected}
                  setAirportSelected={setAirportSelected}
                  isDestination={isDestination}
                  onlyPort={showOnlyPort}
                />
            )) : phase === 'port' && 
            <ShipmentPortsList
              ports={ Object.keys(portList).length>0 ? citySelected : []}
              airports={Object.keys(airports).length>0 ? airportSelected : []}
              setOriginPort={(e:any)=> type ==='sea' ? setOriginPort(e) : setAirPort(e)}
              setTextFieldName={setTextFieldName}
              role='shipmentPortsList'
              typeSelected={type}
              textFieldName={textFieldName}
              phase={phase}
              setPhase={setPhase}
              setCitySelected={setCitySelected}
              setAirportSelected={setAirportSelected}
              isDestination={isDestination}
              onlyPort={showOnlyPort}
            />}  
        </div>
        }
      
    </div>
  );
};
