import React, { useState } from "react";

import { UploadFile } from "..";
import { UploadPhoto } from "../UploadFile/Photo/UploadPhoto";

import { DocsFileValidation, SpreadSheetFileValidation, PDFFileValidation, SizeFileValidation, ImageFileValidation } from "../../Consts/FilesValidation";

import './FilesFLCShared.css';
import { UploadFileArray } from "../UploadFile/UploadFileArray/UploadFileArray";
import { DocumentationInterface } from "../../interfaces/Documentation";
import { OperationInterface } from "../../interfaces/Operation";
import { OrderInterface } from "../../interfaces/OrderInterface";
import { toast } from "react-toastify";
import StoreService from "../../Services/Store.service";
import { CreateDocumentation, UpdateDocumentation } from "../../Services/Documentation.service";
import { SetOrder } from "../../Services/Order.service";
import { Loader } from "../Loader/Loader";
import { useSelector } from "react-redux";

interface FilesFLCSharedProps {
    documentation: DocumentationInterface
    setDocumentation: React.Dispatch<React.SetStateAction<DocumentationInterface>>;
    operationDetail: OperationInterface;
    orderDetail: OrderInterface;
    setOrderDetail: React.Dispatch<React.SetStateAction<OrderInterface>>;
    disabled?: boolean;
    hiddenTitle?: boolean;
    supplier?: boolean;
    multimedia?: boolean;
}

export const FilesFLCShared: React.FC<FilesFLCSharedProps> = ({
    documentation,
    setDocumentation,
    operationDetail,
    orderDetail,
    setOrderDetail,
    hiddenTitle  = false,
    disabled = false,
    supplier = false,
    multimedia = false,
}) => {

    const { userDetail } = useSelector((state: any) => state.user);

    const [loading, setLoading] = useState<boolean>(false);

    const [comercialInvoice, setComercialInvoice] = useState<any>(undefined);
    const [packingList, setPackingList] = useState<any>(undefined);
    const [billLading, setBillLading] = useState<any>(undefined);
    const [insurancePolicy, setInsurancePolicy] = useState<any>(undefined);
    const [additionDocumentation, setAdditionDocumentation] = useState<any>(undefined);
    // const [lettering, setLettering] = useState<{name: ''; url: ''}[]>([]);
    
    const updateDocumentationModel = async (tmpDocumentation: DocumentationInterface) => {
       try {
        const response = await UpdateDocumentation(tmpDocumentation);
        toast.success("Los archivos se actualizaron exitosamente");
        setDocumentation(tmpDocumentation);
       } catch (error) {
        // console.log("🚀 ~ file: FilesFLC.tsx ~ line 56 ~ updateDocumentationModel ~ error", error)
        toast.error("Hubo un error al actualizar el archivo...")
       }
    }

    const createdDocumentationModel = async (tmpDocumentation: DocumentationInterface) => {
        // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 475 ~ handleInvoiceChange ~ tmpBillingReview", tmpDocumentation)
        setDocumentation({...tmpDocumentation, createdAt: new Date()});
       try {
        const responseDocumentation = await CreateDocumentation({...tmpDocumentation, createdAt: new Date()});
        if(responseDocumentation) {
            setOrderDetail((prev:any) => ({
                ...prev,
                documentationId: responseDocumentation.id,
                updatedAt: new Date(),
                activePhase: 2,
            }));
            const responseOrder = await SetOrder({...orderDetail, documentationId: responseDocumentation.id, activePhase: 2, updatedAt:new Date()});
            // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 497 ~ handleDocumentation ~ responseOrder", responseOrder);
            toast.success('Los archivos se guardaron exitosamente! 📁');
        } else {
            toast.error("hubo un error al almacenar los documentos 😕")
        }
       } catch (error) {
        console.log("🚀 ~ file: FilesFLC.tsx ~ line 80 ~ createdDocumentationModel ~ error", error)
        toast.error("Hubo un error al guardar el archivo...")
       }
    }

    /** Subiendo archivos de factura comercial */
    const handleComercialInvoiceChange = async (d:any) => {
        // console.log(d.target.files[0]);
        if(d .target.files[0] && operationDetail && orderDetail) {
            // toast("Subiendo Archivos...");
            try {
                setLoading(true)
                const responseFile = await StoreService.UploadFile(d.target.files[0], operationDetail.id+`/documentation/comercialInvoice/`+d.target.files[0].name, userDetail.id || "")
                // console.log("🚀 ~ file: ProcessPage.tsx ~ line 577 ~ handleDocumentationChange ~ responseFile", responseFile)
                
                if(responseFile) {
                    let tmpDocumentation: DocumentationInterface = {
                        ...documentation,
                        companyId: orderDetail?.companyId,
                        orderId: orderDetail?.id ? orderDetail?.id: '',
                        serviceId: orderDetail.serviceId,
                        userId: orderDetail.user.id,
                        operationId: orderDetail.operationId,
                        comercialInvoice: [...documentation.comercialInvoice, {
                            name: d.target.files[0].name,
                            url: responseFile.toString(),
                            userId: orderDetail.user.id,
                            size: d.target.files[0].size/1024
                        }],
                        status: true,
                        updatedAt: new Date()
                    }

                    if(orderDetail?.documentationId && orderDetail.documentationId.length>0) {
                    await updateDocumentationModel(tmpDocumentation);
                    } else {
                        await createdDocumentationModel(tmpDocumentation);
                    }
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: FilesFLC.tsx ~ line 120 ~ handleComercialInvoiceChange ~ error", error)
                toast.error("Hubo un error al subir el archivo a Facturacion comercial");
            }
        } else {
            toast.info("Por favor suba un archivo");
        }
    }

     /** Eliminando archivos de factura comercial */
     const handleDeleteComercialInvoice = async (dd: number) => {
        try {
            setLoading(true);
            let tmpDocumentation: DocumentationInterface = {
                ...documentation,
                comercialInvoice: documentation.comercialInvoice.filter((_, idx) => idx !== dd),
                updatedAt: new Date(),
            }
            setDocumentation(tmpDocumentation);
            const response = await UpdateDocumentation(tmpDocumentation);
            await StoreService.DeleteFile(documentation.comercialInvoice[dd].url, documentation.comercialInvoice[dd].size, operationDetail.id+`/documentation/comercialInvoice/`);
            setLoading(false);
            // console.log("🚀 ~ file: CustomsAgency.tsx ~ line 534 ~ handleDeleteDocument ~ response", response)
            toast.success("¡Se elimino con éxito el archivo!");
        } catch (error) {
            console.log("🚀 ~ file: FilesFLC.tsx ~ line 388 ~ handleDeleteComercialInvoice ~ error", error)
            setLoading(false);
            toast.error("Hubo un error al eliminar la Factura comercial");
        }
    }

    /** Subiendo archivos de lista de empaque */
    const handlePackingListChange = async (d:any) => {
        if(d .target.files[0] && operationDetail && orderDetail) {
            // toast("Subiendo Archivos...");
            try {
                setLoading(true)
                const responseFile = await StoreService.UploadFile(d.target.files[0], operationDetail.id+`/documentation/packingList/`+d.target.files[0].name,  userDetail.id || "")
                // console.log("🚀 ~ file: ProcessPage.tsx ~ line 577 ~ handleDocumentationChange ~ responseFile", responseFile)
                
                if(responseFile) {
                    let tmpDocumentation: DocumentationInterface = {
                        ...documentation,
                        companyId: orderDetail?.companyId,
                        orderId: orderDetail?.id ? orderDetail?.id: '',
                        serviceId: orderDetail.serviceId,
                        userId: orderDetail.user.id,
                        operationId: orderDetail.operationId,
                        packingList: [...documentation.packingList, {
                            name: d.target.files[0].name,
                                url: responseFile.toString(),
                                userId: orderDetail.user.id,
                                size: d.target.files[0].size
                        }],
                        status: true,
                        updatedAt: new Date()
                    }

                    if(orderDetail?.documentationId && orderDetail.documentationId.length>0) {
                    await updateDocumentationModel(tmpDocumentation);
                    } else {
                        await createdDocumentationModel(tmpDocumentation);
                    }
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: FilesFLC.tsx ~ line 164 ~ handlePackingListChange ~ error", error)
                toast.error("Hubo un error al subir el archivo a la Lista de empaque");
            }
        } else {
            toast.info("Por favor suba un archivo");
        }
    }

    /** Eliminando archivos de la lista de empaques */
    const handleDeletePackingList = async (dd: number) => {
        try {
            setLoading(true);
            let tmpDocumentation: DocumentationInterface = {
                ...documentation,
                packingList: documentation.packingList.filter((_, idx) => idx !== dd),
                updatedAt: new Date(),
            }
            setDocumentation(tmpDocumentation);
            const response = await UpdateDocumentation(tmpDocumentation);
            await StoreService.DeleteFile(documentation.packingList[dd].url, documentation.packingList[dd].size, userDetail.id || "");
            setLoading(false);
            toast.success("¡Se elimino con éxito el archivo!");
       } catch (error) {
            setLoading(false);
            console.log("🚀 ~ file: FilesFLC.tsx ~ line 409 ~ handleDeletePackingList ~ error", error)
            toast.error("Hubo un error al eliminar el archivo de la Lista de empaque");
       }
    }

    /** Subiendo archivos de guia de carga */
    const handleBillLadingChange = async (d:any) => {
        // console.log(d.target.files[0]);
        if(d .target.files[0] && operationDetail && orderDetail) {
            // toast("Subiendo Archivos...");
            try {
                setLoading(true)
                const responseFile = await StoreService.UploadFile(d.target.files[0], operationDetail.id+`/documentation/billLading/`+d.target.files[0].name, userDetail.id || "")
                
                if(responseFile) {
                    let tmpDocumentation: DocumentationInterface = {
                        ...documentation,
                        companyId: orderDetail?.companyId,
                        orderId: orderDetail?.id ? orderDetail?.id: '',
                        serviceId: orderDetail.serviceId,
                        userId: orderDetail.user.id,
                        operationId: orderDetail.operationId,
                        billLading: [...documentation.billLading, {
                            name: d.target.files[0].name,
                            url: responseFile.toString(),
                            userId: orderDetail.user.id,
                            size: d.target.files[0].size
                        }],
                        status: true,
                        updatedAt: new Date()
                    }

                    if(orderDetail?.documentationId && orderDetail.documentationId.length>0) {
                    await updateDocumentationModel(tmpDocumentation);
                    } else {
                        await createdDocumentationModel(tmpDocumentation);
                    }
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: FilesFLC.tsx ~ line 208 ~ handleBillLadingChange ~ error", error)
                toast.error("Hubo un error al subir el archivo a la Guia de carga");
            }
        } else {
            toast.info("Por favor suba un archivo");
        }
    }

    /** Eliminando archivos de guia de carga */
    const handleDeleteBillLading = async (dd: number) => {
        try {
            setLoading(true);
            let tmpDocumentation: DocumentationInterface = {
                ...documentation,
                billLading: documentation.billLading.filter((_, idx) => idx !== dd),
                updatedAt: new Date(),
            }
            setDocumentation(tmpDocumentation);
            const response = await UpdateDocumentation(tmpDocumentation);
            await StoreService.DeleteFile(documentation.billLading[dd].url, documentation.billLading[dd].size, userDetail.id || "");
            setLoading(false);
            //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 534 ~ handleDeleteDocument ~ response", response)
            toast.success("¡Se elimino con éxito el archivo! 😎");
        } catch (error) {
            //console.log("🚀 ~ file: FilesFLC.tsx ~ line 429 ~ handleDeleteBillLading ~ error", error)
            setLoading(false);
            toast.error("Hubo un error al eliminar el archivo de la Guía de carga");
        }
    }

    /** Subiendo archivos de poliza de seguro */
    const handleInsurancePolicyChange = async (d:any) => {
        // console.log(d.target.files[0]);
        if(d .target.files[0] && operationDetail && orderDetail) {
            // toast("Subiendo Archivos...");
            try {
                setLoading(true)
                const responseFile = await StoreService.UploadFile(d.target.files[0], operationDetail.id+`/documentation/insurancePolicy/`+d.target.files[0].name, userDetail.id || "")
               
                if(responseFile) {
                    let tmpDocumentation: DocumentationInterface = {
                        ...documentation,
                        companyId: orderDetail?.companyId,
                        orderId: orderDetail?.id ? orderDetail?.id: '',
                        serviceId: orderDetail.serviceId,
                        userId: orderDetail.user.id,
                        operationId: orderDetail.operationId,
                        insurancePolicy: [...documentation.insurancePolicy, {
                            name: d.target.files[0].name,
                            url: responseFile.toString(),
                            userId: orderDetail.user.id,
                            size: d.target.files[0].size
                        }],
                        status: true,
                        updatedAt: new Date()
                    }

                    if(orderDetail?.documentationId && orderDetail.documentationId.length>0) {
                    await updateDocumentationModel(tmpDocumentation);
                    } else {
                        await createdDocumentationModel(tmpDocumentation);
                    }
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                //console.log("🚀 ~ file: FilesFLC.tsx ~ line 251 ~ handleInsurancePolicyChange ~ error", error)
                toast.error("Hubo un error al subir el archivo a la Póliza de seguro");
            }
        } else {
            toast.info("Por favor suba un archivo 😬");
        }
    }

    /** Eliminando archivos poliza de seguro */
    const handleDeleteInsurancePolicy = async (dd: number) => {
        try {
            setLoading(true);
            let tmpDocumentation: DocumentationInterface = {
                ...documentation,
                insurancePolicy: documentation.insurancePolicy.filter((_, idx) => idx !== dd),
                updatedAt: new Date(),
            }
            setDocumentation(tmpDocumentation);
            const response = await UpdateDocumentation(tmpDocumentation);
            await StoreService.DeleteFile(documentation.insurancePolicy[dd].url, documentation.insurancePolicy[dd].size, userDetail.id || "");
            setLoading(false);
            //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 534 ~ handleDeleteDocument ~ response", response)
            toast.success("¡Se elimino con éxito el archivo! 😎");
        } catch (error) {
            setLoading(false);
            //console.log("🚀 ~ file: FilesFLC.tsx ~ line 452 ~ handleDeleteInsurancePolicy ~ error", error)
            toast.error("Hubo un error al eliminar el archivo de Póliza de seguro");
        }
    }

    /** Subiendo archivos extras*/
    const handleAdditionDocumentationChange = async (d:any) => {
        //console.log(d.target.files[0]);
        if(d .target.files[0] && operationDetail && orderDetail) {
            // toast("Subiendo Archivos...");
            setLoading(true)
            try {
                const responseFile = await StoreService.UploadFile(d.target.files[0], operationDetail.id+`/documentation/additionDocumentation/`+d.target.files[0].name, userDetail.id || "")
                //console.log("🚀 ~ file: ProcessPage.tsx ~ line 577 ~ handleDocumentationChange ~ responseFile", responseFile)
                
                if(responseFile) {
                    let tmpDocumentation: DocumentationInterface = {
                        ...documentation,
                        companyId: orderDetail?.companyId,
                        orderId: orderDetail?.id ? orderDetail?.id: '',
                        serviceId: orderDetail.serviceId,
                        userId: orderDetail.user.id,
                        operationId: orderDetail.operationId,
                        additionDocumentation: [...documentation.additionDocumentation,{
                            name: d.target.files[0].name,
                            url: responseFile.toString(),
                            userId: orderDetail.user.id,
                            size: d.target.files[0].size
                        }],
                        status: true,
                        updatedAt: new Date()
                    }

                    if(orderDetail?.documentationId && orderDetail.documentationId.length>0) {
                    await updateDocumentationModel(tmpDocumentation);
                    } else {
                        await createdDocumentationModel(tmpDocumentation);
                    }
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                // console.log("🚀 ~ file: FilesFLC.tsx ~ line 294 ~ handleAdditionDocumentationChange ~ error", error)
                toast.error("Hubo un error al subir el archivo a Documentación extra");
            }
        } else {
            toast.info("Por favor suba un archivo");
        }
    }

    /** Eliminando archivos extras */
    const handleDeleteAdditionDocumentation = async (dd: number) => {
        try {
            setLoading(true);
            let tmpDocumentation: DocumentationInterface = {
                ...documentation,
                additionDocumentation: documentation.additionDocumentation.filter((_, idx) => idx !== dd),
                updatedAt: new Date(),
            }
            setDocumentation(tmpDocumentation);
            const response = await UpdateDocumentation(tmpDocumentation);
            await StoreService.DeleteFile(documentation.additionDocumentation[dd].url, documentation.additionDocumentation[dd].size, userDetail.id || "");
            setLoading(false);
            //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 534 ~ handleDeleteDocument ~ response", response)
            toast.success("¡Se elimino con éxito el archivo!");
        } catch (error) {
            setLoading(false);
            //console.log("🚀 ~ file: FilesFLC.tsx ~ line 471 ~ handleDeleteAdditionDocumentation ~ error", error)
            toast.error("Hubo un error al eliminar el de archivo de Documentación extra");
        }
    }

    /** Subiendo archivos extras*/
    const handleLetteringChange = async (itemImg: {name: string, url: string, fileSize: number}) => {
        // console.log(itemImg);
        if(itemImg.url.length>0 && itemImg.name.length>0 && operationDetail && orderDetail) {
            // toast("Subiendo Archivos...");
            setLoading(true)
            try {
                const responseFile = await StoreService.UploadString(itemImg.url, operationDetail.id+`/documentation/lettering/`+itemImg.name, (userDetail.id || ""), itemImg.fileSize)
                if(responseFile) {
                    let tmpDocumentation: DocumentationInterface = {
                        ...documentation,
                        companyId: orderDetail?.companyId,
                        orderId: orderDetail?.id ? orderDetail?.id: '',
                        serviceId: orderDetail.serviceId,
                        userId: orderDetail.user.id,
                        operationId: orderDetail.operationId,
                        lettering:[...documentation.lettering, {
                            name: itemImg.name,
                            url: responseFile.toString(),
                            userId: orderDetail.user.id,
                            size: itemImg.fileSize
                        }],
                        status: true,
                        updatedAt: new Date()
                    }
    
                    if(orderDetail?.documentationId && orderDetail.documentationId.length>0) {
                       await updateDocumentationModel(tmpDocumentation);
                    } else {
                        await createdDocumentationModel(tmpDocumentation);
                    }
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: FilesFLC.tsx ~ line 337 ~ handleLetteringChange ~ error", error)
                toast.error("Hubo un error al subir el archivo a Rotulación");
            }
        } else {
            toast.info("Por favor suba un archivo");
        }
    }

      /** Eliminando archivos de rotulacion SUNAT */
    const handleDeleteLettering = async (i: number) => {
        try {
            setLoading(true);
            let tmpDocumentation: DocumentationInterface = {
                ...documentation,
                lettering: documentation.lettering.filter((_, idx) => idx !== i),
                updatedAt: new Date(),
            }
            setDocumentation(tmpDocumentation);
            const response = await UpdateDocumentation(tmpDocumentation);
            await StoreService.DeleteFile(documentation.lettering[i].url, documentation.lettering[i].size, userDetail.id || "");
            setLoading(false);
            //console.log("🚀 ~ file: CustomsAgency.tsx ~ line 534 ~ handleDeleteDocument ~ response", response)
            toast.success("¡Se elimino con éxito el archivo!");
        } catch (error) {
            setLoading(false);
            //console.log("🚀 ~ file: FilesFLC.tsx ~ line 360 ~ handleDeleteLettering ~ error", error)
            toast.error("Hubo un error al eliminar el de archivo de Rotulación");
        }
    }      
    
    return (
        <div role='filesFLCRole' className="filesFLCShared-container">
            {!hiddenTitle && <div className="filesFLCShared-header">
                <div className="big-header filesFLCShared-title">No más archivos perdidos</div>
                <div className="big-subheader filesFLCShared-color">Toda la documentación estará siempre ordenada</div>
            </div>}
            {!loading ? 
            <div className="filesFLCShared-files">
                <UploadFileArray 
                    title="Factura comercial"
                    subtitle="Recuerda que según tu aduana, la factura debe contar obligatoriamente con el nombre, dirección, email y teléfono del proveedor. "
                    buttonContent="Subir archivo"
                    fileValue={comercialInvoice}
                    file={documentation.comercialInvoice}
                    handleFileValue={handleComercialInvoiceChange}
                    acceptType={DocsFileValidation+","+SpreadSheetFileValidation+","+PDFFileValidation+","+ImageFileValidation}
                    handleDelete={handleDeleteComercialInvoice}
              
                />
                <UploadFileArray 
                     title="Lista de empaque"
                     subtitle="Recuerda que tienes que declarar la cantidad de bultos sueltos correctamente, si no, es multa de SUNAT."
                     buttonContent="Subir archivo"
                     fileValue={packingList}
                     file={documentation.packingList}
                     disabled={disabled}
                     handleFileValue={handlePackingListChange}
                     acceptType={DocsFileValidation+","+SpreadSheetFileValidation+","+PDFFileValidation+","+ImageFileValidation}
                     handleDelete={handleDeletePackingList}
             
                />
                <UploadFileArray 
                     title="Documentación extra"
                     subtitle="Puedes subir tus permisos de importación, tus certificados de origen, tus comprobantes de pago al proveedor, etc."
                     buttonContent="Subir archivo"
                     fileValue={additionDocumentation}
                     file={documentation.additionDocumentation}
                     disabled={disabled}
                     handleFileValue={handleAdditionDocumentationChange}
                     acceptType={DocsFileValidation+","+SpreadSheetFileValidation+","+PDFFileValidation+","+ImageFileValidation}
                     handleDelete={handleDeleteAdditionDocumentation}
                   
                />
            </div>
            : <Loader />} 
        </div>
    )
}