import { lightFormat, format } from 'date-fns'
import { es } from 'date-fns/locale'

export const FormatDate = (value: Date) => {
    let date_string = lightFormat(new Date(value), 'dd/MM/yyyy');
    return date_string;
}

// method to format 12 may 2024
export const FormatDateMinString = (value: Date): string => {
    return format(value, 'dd MMM yyyy', { locale: es });
};