import React, { useEffect, useState } from "react";

/** importando componentes */
import { ArrowUp } from "../../../../icons/outline/ArrowUp";
import { ArrowDown } from "../../../../icons/solid/ArrowDown";

import { ContainerType } from "../../../../interfaces/ContainerType";

import './RowQuote.css';


interface RowQuoteProps {
    open?: boolean; // estado de collapse;
    open2?: boolean;
    open3?: boolean;
    originCountry: string; // pais de origen
    transportInternational: string;
    safe: string;
    expensesOrigin: string;
    subTotal1: string;
    subTotal2: string;
    destinationCountry: string;
    storage: string;
    // warehouseExpenses: string;
    storageExpenses: string;
    onCosts: string;
    operatingExpenses: string;
    administrativeExpensive: string;
    desadunaje: string;
    approval: string;
    localTransport: string;
    handling: string;
    // typeTransport: string;
    deconsolidation: string;
    returnContain: string;
    commissionCustoms: string;
    otherExpenses: string;
    typeContainter: ContainerType | undefined // tipo de contenedor
    tax: string;
    total: string;
    insurance: boolean; // la cotizacion tiene seguro ?
    fullfield?: boolean;
    isCourier?: boolean;

    /* Taxes */
    toValue: string;
    igv: string;
    ipm: string;
    perception: string;
    isc: string;
    specificRight: string;
    antidumpi: string;
    surcharge: string;
    subTotal3: string;
    impuesto: boolean;
}

export const RowQuote: React.FC<RowQuoteProps> = ({
    open,
    open2,
    open3,
    originCountry,
    transportInternational,
    safe,
    expensesOrigin,
    subTotal1,
    subTotal2,
    destinationCountry,
    storage,
    // warehouseExpenses,
    storageExpenses,
    onCosts,
    operatingExpenses,
    administrativeExpensive,
    desadunaje,
    approval,
    localTransport,
    returnContain,
    handling,
    otherExpenses,
    // typeTransport,
    deconsolidation,
    commissionCustoms,
    tax,
    total,
    typeContainter = {
        lcl: false,
        fcl: false,
        lclAir: false
    },
    insurance,
    fullfield = true,
    isCourier = false,

    /** Taxes */
    toValue,
    igv,
    ipm,
    perception,
    isc,
    specificRight,
    antidumpi,
    surcharge,
    subTotal3,
    impuesto = false,
}) => {

    const [collapse, setCollapse] = useState(open);
    const [collapse2, setCollapse2] = useState(open2);
    const [collapse3, setCollapse3] = useState(open3);
    
   
    return (
        <div className="rowQuote-container">

            {/** Primer bloque  */ } 
           
            <div className="rowQuote-header-container rowQuote-header--pointer" onClick={()=>setCollapse(!collapse)}>
                <div className="paragraph-header rowQuote-header--title">{`Servicios en origen ${(originCountry)}`}</div>
                {!collapse ? <ArrowDown className="rowQuote-header--pointer"/> : 
                <ArrowUp className="rowQuote-header--pointer" />}
            </div>
            { collapse &&
                <div className="rowQuote-body">
                    {Number(transportInternational)!==0 &&  <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Transporte internacional</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{transportInternational}</div>
                    </div>}
                   {Number(safe)!==0 && fullfield && <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Seguro</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{safe}</div>
                    </div>}
                    {fullfield && Number(expensesOrigin) !==0 && <div className={`rowQuote__ ${insurance ? 'rowQuote_transparent': 'rowQuote_white'}`} >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Gastos de Origen</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{expensesOrigin}</div>
                    </div>}
                    <div className="rowQuote_black" >
                        <div className="paragraph" style={{color: "var(--white)"}}>Subtotal 1</div>
                        <div className="paragraph" style={{color: "var(--white)"}}>{subTotal1}</div>
                    </div>
                </div>
            }

            {/** Segundo bloque  */ } 
            <div className="rowQuote-header-container rowQuote-header--pointer" onClick={()=>setCollapse2(!collapse2)}>
                <div className="paragraph-header rowQuote-header--title">{`Servicios en destino ${(destinationCountry)}`}</div>
                {!collapse2 ? <ArrowDown className="rowQuote-header--pointer"/> : 
                <ArrowUp className="rowQuote-header--pointer" />}
            </div>
            { collapse2 &&
                <div className="rowQuote-body">
                    {!isCourier && Number(approval)!==0 && <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Visto bueno</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{approval}</div>
                    </div>}
                    {Number(storage) !==0 && <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Almacén</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{storage}</div>
                    </div>}
                    {!isCourier && (typeContainter.lcl || typeContainter.lclAir) && Number(deconsolidation)!==0 && <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Desconsolidación</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{deconsolidation}</div>
                    </div>}
                    {handling && Number(handling)!==0 &&  <div className={`rowQuote__ ${typeContainter.fcl ? 'rowQuote_transparent': 'rowQuote_white'}`} >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Handling</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{handling}</div>
                    </div>}
                    {!isCourier && fullfield && Number(storageExpenses) !==0 && <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Gastos de almacenaje</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{storageExpenses}</div>
                    </div>}
                    {Number(operatingExpenses)!==0 && <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Gastos operativos</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{operatingExpenses}</div>
                    </div>}
                    {!isCourier && fullfield && Number(administrativeExpensive)!==0 && <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Gastos administrativos</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{administrativeExpensive}</div>
                    </div>}


                   {/** Solo se muestra en courier */} 
                    {isCourier && Number(desadunaje) !==0 && <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Desadunaje</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{desadunaje}</div>
                    </div>}


                    {Number(localTransport) !==0 && <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Transporte local</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{localTransport}</div>
                    </div>}
                    {!isCourier && Number(commissionCustoms) !==0 && <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Comisión por agenciamiento de aduana</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{commissionCustoms}</div>
                    </div>}
                    {!isCourier && fullfield && Number(onCosts)!==0 && <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Sobrecostos</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{onCosts}</div>
                    </div>}
                    {!isCourier && typeContainter.fcl && Number(returnContain)!==0 && <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Devolución del contenedor</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{returnContain}</div>
                    </div>}
                    {fullfield && Number(otherExpenses)!==0 && <div className="rowQuote__ rowQuote_transparent" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Otros gastos</div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{otherExpenses}</div>
                    </div>}
                    {Number(tax)!==0 && <div className="rowQuote__ rowQuote_white" >
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>IGV (18%) </div>
                        <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{tax}</div>
                    </div>}
                 
                    
                    <div className="rowQuote_black" >
                        <div className="paragraph" style={{color: "var(--white)"}}>Subtotal 2</div>
                        <div className="paragraph" style={{color: "var(--white)"}}>{subTotal2}</div>
                    </div>
                </div>
            }

            {/** Tercer bloque  */ } 
            {!impuesto ?  <>
                <div className="rowQuote-header-container rowQuote-header--pointer" onClick={()=>setCollapse3(!collapse3)}>
                    <div className="paragraph-header rowQuote-header--title">Impuestos</div>
                    {!collapse3 ? <ArrowDown className="rowQuote-header--pointer"/> : 
                    <ArrowUp className="rowQuote-header--pointer" />}
                </div>
                { collapse3 && 
                    <div className="rowQuote-body">
                        <div className="rowQuote__ rowQuote_white" >
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Ad Valorem</div>
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{toValue}</div>
                        </div>
                        {Number(igv)!==0 && <div className="rowQuote__ rowQuote_white">
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>IGV {!isCourier? "(16%)" : "(18%)"}</div>
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{igv}</div>
                        </div>}
                        {Number(ipm)!==0 && <div className="rowQuote__ rowQuote_white">
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>IPM (2%)</div>
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{ipm}</div>
                        </div>}
                        {Number(perception)!==0 && <div className="rowQuote__ rowQuote_white">
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Percepción {!isCourier ? "" : "(3.5%)"}</div>
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{perception}</div>
                        </div>}
                        {!isCourier && Number(isc)!==0 && <div className="rowQuote__ rowQuote_white">
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Impuesto Selectivo al Consumo</div>
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{isc}</div>
                        </div>}
                        {!isCourier && Number(specificRight)!==0 && <div className="rowQuote__ rowQuote_white">
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Derecho Específicos</div>
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{specificRight}</div>
                        </div>}
                        {!isCourier && Number(antidumpi)!==0 && <div className="rowQuote__ rowQuote_white">
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Derecho Antidumping</div>
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{antidumpi}</div>
                        </div>}
                        {!isCourier && Number(surcharge)!==0 && <div className="rowQuote__ rowQuote_white">
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>Sobretasa</div>
                            <div className="paragraph rowQuote__text" style={{color: "var(--gray-1)"}}>{surcharge}</div>
                        </div>}

                        <div className="rowQuote_black" >
                            <div className="paragraph" style={{color: "var(--white)"}}>Subtotal 3</div>
                            <div className="paragraph" style={{color: "var(--white)"}}>{subTotal3}</div>
                        </div>
                    </div>
                }
            </> : ''}

            {/** Footer total*/}
            <div className="paragraph-header rowQuoteFooter__">
                <div className="paragraph-header" style={{color: "var(--gray-1)"}}>Total USD</div>
                <div className="paragraph-header" style={{color: "var(--gray-1)"}}>$ {total} </div>
            </div>
        </div>
    )
}